import React, { createContext, useContext } from 'react';

interface FooterContext {
  isShowing: boolean;
  showFooter: (show: boolean) => void;
}

const footerContext = createContext<FooterContext>({
  isShowing: true,
  showFooter: () => {
    /* do nothing */
  },
});

export function ProviderFooter({ children }: { children: React.ReactNode }) {
  const footer = useProviderFooter();
  return (
    <footerContext.Provider value={footer}>{children}</footerContext.Provider>
  );
}

export const useFooter = () => {
  return useContext(footerContext);
};

function useProviderFooter() {
  const [isShowing, setIsShowing] = React.useState(true);
  const showFooter = (show: boolean) => setIsShowing(show);

  return {
    isShowing,
    showFooter,
  };
}
