import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { queryCompanies } from 'apis';

import { useAuth } from 'app/ProviderAuth';

export function AdminCompaniesPage() {
  const { isLoading: userIsLoading, isAdmin } = useAuth();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const querySnapshot = await queryCompanies('company', 'asc');

      const companies = [];
      querySnapshot.forEach((doc) => {
        const item = doc.data();
        companies.push({ ...item, id: doc.id });
      });
      setCompanies(companies);
    }
    fetchData();
  }, []);

  function renderRow(row) {
    return (
      <TableRow key={row.id}>
        <TableCell>{row.company}</TableCell>
        <TableCell>
          <Link to={`/admin/companies/${row.id}`}>Edit</Link>
        </TableCell>
      </TableRow>
    );
  }

  if (userIsLoading) {
    return null;
  }

  if (!isAdmin) {
    return <Navigate to="/companies" replace />;
  }

  return (
    <Box mx="8%">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{companies && companies.map(renderRow)}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
