import React from 'react';

import { Grid, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from 'components';
import { BaseTextField } from './BaseTextField';
import { UploadFileProps } from './UploadFile.types';

const useStyles = makeStyles(() => ({
  button: {
    '& .MuiInputBase-root': { paddingRight: '10px' },
  },
}));
export const UploadFile: React.FC<UploadFileProps> = ({
  /**
   * Styled upload file component built on top of mui components.
   */
  accept,
  error,
  helperText,
  label,
  onFileChange,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = React.useState('');
  const classes = useStyles();
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files?.[0];

    if (file) {
      setFileName(file.name);
    }

    if (file && onFileChange) {
      onFileChange(file);
    }
  };

  return (
    <Grid>
      <input
        accept={accept}
        onChange={handleChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
      <BaseTextField
        disabled
        fullWidth
        error={error}
        helperText={helperText}
        label={label}
        value={fileName}
        className={classes.button}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button onClick={() => fileInputRef?.current?.click()}>
                選擇檔案
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};
