import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';

import { useAuth } from 'app/ProviderAuth';
import { getReferrer } from 'apis';
import { Button, ReferrerInfoCard } from 'components';
import { Referrer } from 'types';

/**
 * Apply completed.
 */
export const ApplyCompletedPage: React.FC = () => {
  const { referrerId } = useParams<{
    companyName: string;
    referrerId: string;
  }>();
  const { user } = useAuth();
  const [referrerInfo, setReferrerInfo] = React.useState<Referrer>();

  React.useEffect(() => {
    const loadData = async () => {
      if (referrerId) {
        const referrer = await getReferrer(referrerId);
        setReferrerInfo(referrer.data());
      }
    };
    loadData();
  }, [referrerId]);

  if (!user || !referrerInfo) {
    return null;
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <Box mt={4} mb={8} display="flex" flexDirection="column">
            <Typography variant="h2">已送出內推申請</Typography>
            <Box mt={2}>
              <Typography>
                系統已經成功寄出信件通知推薦人 {referrerInfo.name}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button href="/applications/sent">查看內推進度</Button>
            </Box>
            <Box mt={2}>
              <Button color="secondary" href="/companies">
                返回首頁
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box mt={4}>
            <Typography variant="h2">建立連結</Typography>
            <Box mt={2}>
              <Typography>
                多數內推人和申請人建立職涯關係，在海外互助發光
              </Typography>
            </Box>
            {referrerInfo && <ReferrerInfoCard referrer={referrerInfo} />}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
