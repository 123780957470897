import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';

import { useAuth } from 'app/ProviderAuth';

import { getApplication } from 'apis';
import { Application } from 'types';
import { ApplicationInfoCard, Button } from 'components';

/**
 * Refer completed.
 */
export const ReferCompletedPage: React.FC = () => {
  const { applicationId } = useParams<{
    applicationId: string;
  }>();
  const { user } = useAuth();

  const [application, setApplication] = React.useState<Application>();

  React.useEffect(() => {
    const loadData = async () => {
      if (applicationId) {
        const app = await getApplication(applicationId);
        setApplication(app);
      }
    };
    loadData();
  }, [applicationId]);

  if (!user || !application) {
    return null;
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <Box mt={4} mb={8} display="flex" flexDirection="column">
            <Typography variant="h2">感謝你，內推任務已完成！</Typography>
            <Box mt={2}>
              <Typography>
                我們已寄出信件通知 {application.firstName}
              </Typography>
              <Typography>
                如果申請人在 LinkedIn
                聯繫你，不妨也可以加個好友聊個天；大家都在海外打拚，別忘了人脈也是實力的一部份
                :D
              </Typography>
            </Box>
            <Box mt={2}>
              <Button href="/applications">繼續內推</Button>
            </Box>
            <Box mt={2}>
              <Button color="secondary" href="/companies">
                返回首頁
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box mt={4}>
            <Typography variant="h2">NEX Work 幫你 Network</Typography>
            <Box mt={2}>
              <Typography>
                多數內推人和申請人建立職涯關係，在海外互助發光
              </Typography>
            </Box>
            {application && <ApplicationInfoCard application={application} />}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
