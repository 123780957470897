import React, { useEffect, useState } from 'react';
import {
  Typography,
  CardContent,
  Card,
  styled,
  Box,
} from '@mui/material';
import { WHITE } from 'constants/colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LineChart } from '@mui/x-charts/LineChart';
import FormControl from '@mui/material/FormControl';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { validScale, getNumByScale, AxisData } from 'helper/time';
import { Timestamp } from 'firebase/firestore';
const PREFIX = 'GraphCard';
const classes = {
  root: `${PREFIX}-root`,
  datePicker: `${PREFIX}-date-picker`,
  timeBar: `${PREFIX}-time-bar`,
};
interface GraphProps {
    tableName: string;
    getDataByTimeRange: (startTime: Date, endTime: Date) => Promise<Timestamp[]>;
}
const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '342px',
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: WHITE,
    borderRadius: '12px',
    boxShadow: '0 4px 4px 0 rgb(0 0 0 / 25%)',

    [theme.breakpoints.up('sm')]: {
      height: '446px',
      padding: '24px 28px',
    },
  },
  [ `& .${classes.datePicker}`]: {
    '& .MuiInputBase-input': {
      padding: '8.5px 0px 8.5px 14px',
    },
  },
  [`& .${classes.timeBar}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SCALES = ['day', 'week', 'month'] as const;
export const GraphCard: React.FC<GraphProps> = ({ tableName, getDataByTimeRange }) => {
  const [scale, setScale] = useState<string>(SCALES[0]);
  const [startDate, setStartDate] = useState( dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState( dayjs() );
  const [data, setData] = useState<Timestamp[]>([]);
  const [axisData, setAxisData] = useState<AxisData>({x: ['0'], y: [0]});
  const [isLoading, setIsLoading] = useState(false);
  const updateGraph = async (data: Timestamp[]) => {
    const axisData = getNumByScale(data, startDate, endDate, scale as ManipulateType);
    setAxisData(axisData);
    setData(data);
  }

  const fetchData = async() => {
    setIsLoading(true);
    const rowData = await getDataByTimeRange(startDate.toDate(), endDate.toDate());
    updateGraph(rowData);
    setIsLoading(false);
  };
  useEffect(() => {
    if (isLoading) return;
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => { updateGraph(data); }, [scale]);

  const handleScaleChange = (event: SelectChangeEvent<string>) => {
    setScale(event.target.value);
  }

  return (
  <StyledCard className={classes.root}>
    <CardContent>
      <Typography variant="h6">{tableName}</Typography>
      <Box className={classes.timeBar} gap={4} sx= {{paddingTop:"16px"}}>
        <Box className={classes.timeBar} gap={1}>
          <Typography>StartAt: </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker 
                className= {classes.datePicker}
                defaultValue={startDate}
                value={startDate}
                onChange={(date: Dayjs | null) => date && setStartDate(date)}
                minDate={dayjs('2020-01-01')}
                maxDate={dayjs()}
              />
          </LocalizationProvider>
        </Box>
          <Box className={classes.timeBar} gap={1} > 
            <Typography >EndAt: </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                className= {classes.datePicker}
                defaultValue={endDate}
                value={endDate}
                onChange={(date: Dayjs | null) => date && setEndDate(date)}
                minDate={startDate}
                maxDate={dayjs()}
              />
            </LocalizationProvider>
          </Box>
          <Box className={classes.timeBar} gap={1} > 
            <Typography >Scale: </Typography>
            <FormControl sx={{m: 1, minWidth:120, padding: "0px"}} size= "small">
              <Select
                value = {scale}
                onChange = {handleScaleChange}
              >
                {SCALES.map((SCALE) => (
                  validScale(startDate, endDate, SCALE) && <MenuItem key={SCALE} value={SCALE}> {SCALE} </MenuItem> 
                ))}
              </Select>
            </FormControl>
          </Box>
      </Box>

      <LineChart
        height={300}
        series={[
          { data: axisData.y,  yAxisKey: 'leftAxisId' },
        ]}
        xAxis={[{ scaleType: 'point', data: axisData.x, id: 'date'}]}
        yAxis={[{ id: 'leftAxisId' }]}
      />
    </CardContent>
  </StyledCard>
  );
}