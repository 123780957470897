import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button, ControlledField, NexForm } from 'components';
import {
  Control,
  FieldErrors,
} from 'react-hook-form';
import { FormEvent } from 'react';

export type Account = {email: string};
export interface ForgotPasswordFormProps {
  control: Control<Account>;
  errors: FieldErrors;
  handleSubmit: (event: FormEvent) => void;
  isSubmitting: boolean;
}
export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  control,
  errors,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <NexForm gap={1} handleSubmit={handleSubmit}>
      <Box> 
        <Typography component="h1" variant="h5" >忘記密碼</Typography>
      </Box>
      <Box display="grid" gap={2.5}>
        <ControlledField
          label="電子郵件 - Email"
          name="email"
          control={control}
          required
          error={errors.email}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button type="submit" disabled={isSubmitting}>
          送出
        </Button>
      </Box>
    </NexForm>
  );
};
