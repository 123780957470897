import * as React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, FieldError, FieldValues } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    fontSize: '18px',
    color: '#141921',
  },
  option: {
    paddingEnd: '26px',
    fontSize: '18px',
  },
  ratio: {
    '& .MuiSvgIcon-root': {
      fontSize: 32,
    },
  },
  root: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  RadioGroup: {
    paddingTop: '8px',
  },
}));

type NexOptions = {
  label: string;
  value: string;
};
interface NexOptionControlProps {
  label: string;
  name: string;
  control: FieldValues['control'];
  options: NexOptions[];
  error?: FieldError;
  required?: boolean;
}

export const NexOption: React.FC<NexOptionControlProps> = ({
  label,
  name,
  control,
  options,
  error,
  required,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          className={classes.formLabel}
          style={Boolean(error) ? { color: '#d32f2f' } : {}}
        >
          {label + (required ? ' *' : '')}
        </FormLabel>
        <Controller
          render={({ field }) => (
            <RadioGroup {...field} className={classes.RadioGroup}>
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={
                    <Radio
                      classes={{ root: classes.root }}
                      className={classes.ratio}
                    />
                  }
                  label={
                    <Typography variant="body1" className={classes.option}>
                      {option.label}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          )}
          name={name}
          control={control}
          rules={{ required: 'true' }}
        />
        {Boolean(error) && (
          <Typography color="error" variant="body2">
            {'此欄位為必填欄位'}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};
