import { applicantsCollection } from 'configs/db';
import {
  doc,
  DocumentSnapshot,
  getDoc,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { Applicant } from 'types';

export const createOrUpdateApplicant = async (
  uid: string,
  data: Applicant
): Promise<void> => {
  const applicantDocRef = doc(applicantsCollection, uid);
  await setDoc(applicantDocRef, {
    ...data,
    createdAt: data.createdAt ?? serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};

export const getApplicantsByUid = async (
  uid: string
): Promise<DocumentSnapshot<Applicant>> => {
  const docRef = doc(applicantsCollection, uid);

  try {
    const result = await getDoc(docRef);
    return result;
  } catch (error) {
    console.error(
      'Error happens when getting Applicant document from firestore: ',
      error
    );
    throw error;
  }
};

