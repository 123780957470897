import React from 'react';

import { Typography, Grid, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { getDaysAgo, displayDaysAgo } from 'helper/common';
import { Application } from 'types';

const useStyles = makeStyles(() => {
  return createStyles({
    nameTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '39px',
    },
    lightText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
      color: '#000000',
      opacity: '0.5',
    },
    about: {
      whiteSpace: 'pre-wrap',
    },
  });
});

export interface ApplicationSentDetailProps {
  application: Application;
}

export const ApplicationSentDetail = ({
  application,
}: ApplicationSentDetailProps) => {
  const {
    about,
    company,
    createdAt,
    jobTitle0,
    linkedIn,
    referrerFeedback,
    referrerName,
    resumeDownloadUrl,
    resumeFirebasePath,
    status,
  } = application;
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={10} xs={12} className={classes.nameTitle}>
          {referrerName} | {company}
        </Grid>
        <Grid item lg={2} className={classes.lightText}>
          <Box display={{ xs: 'none', lg: 'flex' }} justifyContent="flex-end">
            申請時間 : {displayDaysAgo(getDaysAgo(createdAt))}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={2} xs={12}>
          <Grid item lg={12}>
            <Typography variant="subtitle2" noWrap={true}>
              職缺
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1" noWrap>
              {jobTitle0}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Grid>
            <Typography variant="subtitle2" noWrap={true}>
              連結
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={linkedIn}
                style={{ marginRight: '10px', color: 'black' }}
              >
                LinkedIn
              </a>
              <a
                style={{ color: 'black' }}
                target="_blank"
                rel="noopener noreferrer"
                /**
                 * to support some old applications which have resumeDownloadUrl
                 * and their resumeFirebasePath are in forms of resume/<hash>/<filename>
                 */
                href={resumeDownloadUrl || resumeFirebasePath}
              >
                履歷
              </a>
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid>
            <Typography variant="subtitle2" noWrap>
              自我介紹
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1" className={classes.about}>
              {about}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={2} xs={12} className={classes.lightText}>
          <Box display="flex" justifyContent={{ xs: 'center', lg: 'flex-end' }}>
            狀態 : {status}
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.lightText}>
          <Box display={{ lg: 'none' }}>
            申請時間 : {displayDaysAgo(getDaysAgo(createdAt))}
          </Box>
        </Grid>
        {referrerFeedback && (
          <Grid item lg={12} xs={12}>
            <Grid>
              <Typography variant="subtitle2" noWrap={true}>
                推薦人的回饋
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" paragraph>
                {referrerFeedback}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <hr />
    </div>
  );
};
