import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useAuth } from 'app/ProviderAuth';
import { GraphCard } from './components';
import {getApplicationCreateAtByTimeRange, getReferrerCreateAtByTimeRange} from 'apis';
export const AdminHomePage = () => {
  const { isLoading: userIsLoading, isAdmin } = useAuth();

  if (userIsLoading) {
    return null;
  }

  if (!isAdmin) {
    return <Navigate to="/companies" replace />;
  }
  return (
    <Container>
      <Box 
        sx = {{display: 'flex', 
              justifyContent: 'space-between',
              maxWidth: '1000px',
              padding: '16px',
              margin: '0 auto',}}>
          <Link to="/admin/applications">Applications</Link>
          <Link to="/admin/referrers">Referrers</Link>
          <Link to="/admin/companies">Companies</Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gridColumnGap: '16px',
          gridRowGap: '32px',
          maxWidth: '1280px',
          margin: '0 auto',
          padding: '16px',
        }}
      >
      <GraphCard tableName="Applications" getDataByTimeRange={getApplicationCreateAtByTimeRange} />
      <GraphCard tableName="Referrers" getDataByTimeRange={getReferrerCreateAtByTimeRange} />
      </Box>

    </Container>
  );
};
