import React from 'react';
import {
  Typography,
  CardContent,
  CardActions,
  Card,
  styled,
  Box,
} from '@mui/material';

import { GREY1, GREY3, GREY4, WHITE } from 'constants/colors';
import { Button } from 'components';
import { getDaysAgo, displayDaysAgo, getInitializedName } from 'helper/common';
import { Company } from 'types';

interface CompanyCardProps {
  company: Company;
}

const PREFIX = 'CompanyCard';
const classes = {
  root: `${PREFIX}-root`,
  companyLogo: `${PREFIX}-company-logo`,
};
const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '343px',
    maxWidth: '100vw',
    height: '342px',
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: WHITE,
    borderRadius: '12px',
    boxShadow: '0 4px 4px 0 rgb(0 0 0 / 25%)',

    [theme.breakpoints.up('sm')]: {
      width: '400px',
      height: '446px',
      padding: '24px 28px',
    },
  },
  [`& .${classes.companyLogo}`]: {
    overflow: 'hidden',
    width: '64px',
    height: '64px',
    objectFit: 'contain',
    marginBottom: '8px',

    [theme.breakpoints.up('sm')]: {
      marginBottom: '16px',
    },
  },
}));

export const CompanyCard: React.FC<CompanyCardProps> = ({ company }) => {
  const {
    company: companyName,
    logoUrl,
    sponsorship,
    referredApplicationCount,
    referrerCount,
    reviewingApplicationCount,

    latestApplicationDate,
    latestApplicationApplicant,
    latestReferralDate,
    latestReferralApplicant,
  } = company;
  const renderSponsorsVisa = (sponsorsVisa: string) => {
    return sponsorsVisa === 'yes' ? '是' : '否';
  };

  return (
    <StyledCard className={classes.root}>
      <CardContent>
        <Box
          sx={{
            mb: '16px',
            md: {
              mb: '24px',
            },
          }}
        >
          <img
            className={classes.companyLogo}
            src={logoUrl || '/defaultCompanyLogo.png'}
            alt={companyName}
          />
          <Typography noWrap variant="h3">
            {companyName}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: '18px',
              md: { mb: '26px' },
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography noWrap variant="body2" fontWeight={500} color={GREY1}>
                推薦人
              </Typography>
              <Typography noWrap variant="body2" color={GREY3}>
                {referrerCount || 0} 名
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}
            >
              <Typography noWrap variant="body2" fontWeight={500} color={GREY1}>
                公司是否幫辦簽證
              </Typography>
              {renderSponsorsVisa(sponsorship)}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'row', sm: 'column' },
              justifyContent: { xs: 'space-between' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: '16px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  noWrap
                  variant="body2"
                  fontWeight={500}
                  color={GREY1}
                >
                  待內推人數
                </Typography>
                {reviewingApplicationCount || 0} 名
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  flexDirection: 'column',
                  textAlign: 'end',
                }}
              >
                <Typography noWrap variant="body2" color={GREY4}>
                  最新申請{' '}
                  {getInitializedName(latestApplicationApplicant) || '-'}
                </Typography>
                <Typography noWrap variant="body2" color={GREY4}>
                  {displayDaysAgo(getDaysAgo(latestApplicationDate))}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: { xs: 'end', sm: 'start' },
                }}
              >
                <Typography
                  noWrap
                  variant="body2"
                  fontWeight={500}
                  color={GREY1}
                >
                  已送出內推數
                </Typography>
                {referredApplicationCount || 0} 名
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  flexDirection: 'column',
                  textAlign: 'end',
                }}
              >
                <Typography noWrap variant="body2" color={GREY4}>
                  最新送出 {getInitializedName(latestReferralApplicant) || '-'}
                </Typography>
                <Typography noWrap variant="body2" color={GREY4}>
                  {displayDaysAgo(getDaysAgo(latestReferralDate))}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>

      <CardActions>
        <Button
          shape="round"
          fullWidth
          href={`/companies/` + encodeURIComponent(`${companyName}`)}
        >
          幫我內推
        </Button>
      </CardActions>
    </StyledCard>
  );
};
