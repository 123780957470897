import { applicationsCollection } from 'configs/db';
import {
  addDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  orderBy,
  Query,
  query,
  QueryConstraint,
  QuerySnapshot,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Application, ApplicationToWrite } from 'types';

export const applicationRefById = (
  id: string
): DocumentReference<Application> => doc(applicationsCollection, id);

export const putApplication = async (
  application: ApplicationToWrite
): Promise<DocumentReference> =>
  await addDoc(applicationsCollection, application);

export const queryApplications = async (
  sortField?: string,
  direction: 'asc' | 'desc' = 'asc'
): Promise<QuerySnapshot<Application>> => {
  const q = sortField
    ? query(applicationsCollection, orderBy(sortField, direction))
    : query(applicationsCollection);
  return await getDocs(q);
};

export const getApplicationsQuery = ({
  referrerUid,
  applicantUid,
  status,
  sortField,
}: {
  referrerUid?: string;
  applicantUid?: string;
  status?: string;
  sortField?: string;
}): Query<Application> => {
  const constraints: QueryConstraint[] = [];
  if (referrerUid) {
    constraints.push(where('referrerUid', '==', referrerUid));
  }
  if (applicantUid) {
    constraints.push(where('applicantUid', '==', applicantUid));
  }
  if (status) {
    constraints.push(where('status', '==', status));
  }
  if (sortField) {
    constraints.push(orderBy(sortField));
  }

  return query(applicationsCollection, ...constraints);
};

export const updateApplication = async (
  id: string,
  fields: Partial<ApplicationToWrite>
): Promise<void> => await updateDoc(doc(applicationsCollection, id), fields);

export const getApplication = async (
  id: string
): Promise<Application | undefined> => {
  const snapshot = await getDoc(applicationRefById(id));
  if (!snapshot.exists()) {
    console.error(`application '${id}' does not exist`);
    return undefined;
  }

  return snapshot.data();
};

export const getApplicationCreateAtByTimeRange = async (
  start: Date, 
  end: Date
  ): Promise<Timestamp[]> => {
  const q = query(applicationsCollection, where('createdAt', '>=', start), where('createdAt', '<=', end));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => doc.data().createdAt);
}

export const getReferredApplicationNum = async (): Promise<Number> => {
  const q = query(applicationsCollection, where('status', '==', 'referred'));
  const snapshot = await getDocs(q);
  return snapshot.size;
}