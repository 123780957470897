import { Timestamp } from 'firebase/firestore';

export enum ActivityLogsAction {
  ANNOUNCE = 'announce',
  APPLY = 'apply',
  REFER = 'refer',
}

export type ActivityLogs = {
  action: ActivityLogsAction;
  applicant: string;
  company: string;
  message?: string;
  referrer: string;
  timestamp: Timestamp;
};
