export const COLLECTION_ACTIVITY_LOGS = 'activityLogs';
export const COLLECTION_ACTIVITY_STATS = 'activityStatsV2';
export const COLLECTION_ADMINS = 'admins';
export const COLLECTION_CONVERSATIONS = 'conversations';
export const COLLECTION_MESSAGES = 'messages';
// export const COLLECTION_APPLICATIONS = 'applications';
// export const COLLECTION_COMPANIES = 'companies';
// export const COLLECTION_REFERRERS = 'referrers';
export const COLLECTION_APPLICANTS = 'applicants';
export const COLLECTION_APPLICATIONS = 'applicationsV2';
export const COLLECTION_COMPANIES = 'companiesV2';
export const COLLECTION_REFERRERS = 'referrersV2';
