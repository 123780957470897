import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { getActivityTop5Logs, queryCompanies, getAllReferrersNum, getReferredApplicationNum } from 'apis';

import { useAuth } from 'app/ProviderAuth';
import { NexLoadingIcon } from 'components';
import { Company, ActivityLogs } from 'types';
import { makeStyles } from '@mui/styles';

import { CompanyCard, LatestActivityLogs, SearchBar } from './components';
import { sortByStringSimilarity } from 'helper/SortFunction';

import { Box, Container, Typography } from '@mui/material';
const useStyles = makeStyles(( theme ) => ({
  boxLogs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center',
    width: '400px', 
    padding: '0 8px',
    [theme.breakpoints.between(800, 1232)]: {
      width: '816px',
      padding: '24px 28px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '343px',
      padding: '24px 28px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center',
    width: '343px', 
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      padding: '24px 28px',
    },
  },
}));
export const CompaniesListPage = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [latestActivityLogs, setLatestActivityLogs] = useState<ActivityLogs[]>(
    []
  );
  const [linearProgress, setLinearProgress] = useState(true);
  const { isLoading: isUserLoading } = useAuth();
  const [ referrerCount, setReferrerCount] = useState<Number>(0);
  const [ referredApplicationCount, setReferredApplicationCount] = useState<Number>(0);
  const classes = useStyles();
  useEffectOnce(() => {
    const fetchData = async () => {
      const companiesSnapshot = await queryCompanies('company');
      const companies = companiesSnapshot.docs.map(
        (company) => company.data() as Company
      );
      setCompanies(companies);
      const latestActivityLogs = await getActivityTop5Logs();
      setLatestActivityLogs(latestActivityLogs);
      setLinearProgress(false);
      const referrerCount = await getAllReferrersNum();
      setReferrerCount(referrerCount);
      const referredApplicationCount = await getReferredApplicationNum();
      setReferredApplicationCount(referredApplicationCount);
    };

    fetchData();
  });

  const renderCompanyCard = (company: Company) => {
    if (company.referrerCount === 0) {
      return null;
    }
    return <CompanyCard company={company} />;
  };

  if (linearProgress && isUserLoading) {
    return <NexLoadingIcon />;
  }
  return (
    <Box sx={{ margin: '40px auto' }}>
      <Container sx={{ margin: '2em auto' }} maxWidth="sm">
        <SearchBar
          data={companies}
          setData={setCompanies}
          sortMethod={sortByStringSimilarity}
          hint={'輸入公司名稱'}
        />
      </Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gridColumnGap: '16px',
          gridRowGap: '32px',
          maxWidth: '1280px',
          margin: '0 auto',
        }}
      >
        <Box className={classes.box}>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }} >總推薦人人數</Typography>
          <Typography sx={{ fontSize: 36, fontWeight: 'bold', textAlign: 'center' }} >{referrerCount}</Typography>
        </Box>
        <Box className={classes.box}>
          <Typography sx={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }} >已送出內推數</Typography>
          <Typography sx={{ fontSize: 36, fontWeight: 'bold', textAlign: 'center' }} >{referredApplicationCount}</Typography>
        </Box>
        <Box className={classes.boxLogs}>
          <LatestActivityLogs logs={latestActivityLogs} />
        </Box>
        {companies?.map(renderCompanyCard)}
      </Box>
    </Box>
  );
};
