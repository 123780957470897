import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

import { displayDaysAgo, getDaysAgo } from 'helper/common';

import { ActivityLogs } from 'types';

import { AnnounceNudgeAction } from '../AnnounceNudgeAction';
import { ApplyNudgeAction } from '../ApplyNudgeAction';
import { ReferNudgeAction } from '../ReferNudgeAction';
export interface LatestActivityLogsProps {
  logs: ActivityLogs[];
}

export const LatestActivityLogs = ({ logs }: LatestActivityLogsProps) => {
  const renderNudge = (log: ActivityLogs) => {
    if (!log) return null;
    let nudgeAction = null;
    switch (log.action) {
      case 'apply':
        nudgeAction = <ApplyNudgeAction {...log} />;
        break;
      case 'refer':
        nudgeAction = <ReferNudgeAction {...log} />;
        break;
      case 'announce':
        nudgeAction = <AnnounceNudgeAction message={log.message} />;
        break;
      default:
        return null;
    }

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        key={log.timestamp.toString()}
      >
        <Grid item xs={9}>
          {nudgeAction}
        </Grid>
        <Grid item xs={3}>
          <Typography
            fontSize={14}
            align="right"
            gutterBottom
            color="textSecondary"
          >
            {displayDaysAgo(getDaysAgo(log.timestamp))}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item md={12} container direction="column" justifyContent="center">
      <Box fontWeight="fontWeightBold">最新動態</Box>
      {logs.map(renderNudge)}
    </Grid>
  );
};
