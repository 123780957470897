import React, { useState } from 'react';
import { Typography, Grid, Box, CardContent, IconButton, CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from 'components';
import { WHITE } from 'constants/colors';
import { Timestamp } from 'firebase/firestore';

const useStyles = makeStyles((theme) => ({
  Card: {
    display: 'flex',
    flexDirection: 'column',
    background: WHITE,
    borderRadius: '12px',
    boxShadow: '0 4px 4px 0 rgb(0 0 0 / 25%)',
    width: '344px',
    paddingBottom: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  },
  customBackground: {
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: '#F4F6FA',
    padding: '20px 36px 16px 36px',
    [theme.breakpoints.down(991)]: {
      padding: '0',
      height: '218px'
    },
  },
  Box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    flex: 1,
  },
  BoldTextTitle: {
    fontWeight: 500,
    fontSize: '18px',
    fontFamily: "Noto Sans TC",
    textAlign: 'center',
    fontStyle: 'normal',
    lineHeight: '125%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 16px',
  },
  BoldTextContent: {
    fontWeight: 500,
    fontSize: '18px',
    fontFamily: "Noto Sans TC",
    textAlign: 'center',
    fontStyle: 'normal',
    lineHeight: '125%',
  },
  SecondText: {
    fontWeight: 400,
    color: '#4D5461',
    fontStyle: 'normal',
    lineHeight: '1.4',
    height: 'fit-content',
  },
  NoteText: {
    fontfamily: 'Noto Sans TC',
    fontSize: "16px",
    color: '#141921',
    fontStyle: 'normal',
    fontweight: 400,
    lineHeight: '140%'
  },
  divider: {
    width: '1px',
    height: '68px',
    backgroundColor: '#C6CBD3',
  },
  border: {
    borderBottom: '1px solid #C6CBD3',
  },
}));

interface Referrer {
  preferredName?: string;
  name: string;
  department?: string;
  jobTitle: string;
  location?: string;
  applicantNote?: string;
  referredCount?: number;
  applicationCount?: number;
  lastReferredDate?: Timestamp;
  role?: string;
  internship?: string;
  careerPage?: string;
}

interface ReferrerDetailsProps {
  referrer: Referrer;
  onButtonClick: () => void;
}

export const ReferrerDetails = ({
  referrer,
  onButtonClick,
}: ReferrerDetailsProps) => {
  const classes = useStyles();
  const maxLength = 30;

  const [showFullContent, setShowFullContent] = useState(
    referrer.applicantNote && referrer.applicantNote.length > maxLength
  );
  const shortContent = !referrer.applicantNote || referrer.applicantNote.length < maxLength;

  const handleToggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const {
    department = "NA",
    location = "不限地點",
    role = "不限部門職位",
    careerPage,
    applicantNote = ''
  } = referrer;

  const openExternalUrl = () => {
    window.open(careerPage, '_blank');
  };

  return (
    <CardContent
      className={classes.Card}
      sx={{ height: showFullContent || shortContent ? 'auto' : '423px' }}
    >
      <Grid container alignItems="center" justifyContent="center" className={classes.customBackground}>
        <Grid container item xs={12} sx={{ height: "109px", justifyContent: "center" }}>
          <Grid item xs={6}>
            <Box className={classes.Box}>
              <Typography className={classes.SecondText} fontSize="16px">職稱</Typography>
              <Typography className={classes.BoldTextTitle}>{referrer.jobTitle}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.Box}>
              <Typography className={classes.SecondText} fontSize="16px">所屬部門</Typography>
              <Typography className={classes.BoldTextTitle}>{department}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ height: "109px", justifyContent: "center" }}>
          <Grid item xs={6}>
            <Box className={classes.Box}>
              <Typography className={classes.SecondText} fontSize="16px">可內推地點</Typography>
              <Typography className={classes.BoldTextTitle}>{location}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.Box}>
              <Typography className={classes.SecondText} fontSize="16px">可內推職位</Typography>
              <Typography className={classes.BoldTextTitle}>{role}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          padding: 1,
          gap: 2,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <Box gap={1} display="flex" alignItems="flex-end">
          <Typography className={classes.BoldTextContent}>{referrer.preferredName || referrer.name}</Typography>
          {careerPage &&
            <IconButton
              color="primary"
              sx={{ padding: 0 }}
              onClick={openExternalUrl}>
              <img
                src="/mingcute_external-link-fill.svg"
                width="24"
                height="24"
                alt="External Link"
              />
            </IconButton>}
        </Box>
        <Box gap={1} display="flex" flexDirection="column">
          <Typography className={classes.NoteText} noWrap={!showFullContent}>{applicantNote}</Typography>
          {applicantNote.length > maxLength &&
            <IconButton
              color="primary"
              onClick={handleToggleContent}>
              <img
                src={showFullContent ? "/ReadMoreButton.svg" : "/HideMoreButton.svg"}
                width="24"
                height="17"
                alt="Toggle More"
              />
            </IconButton>}
        </Box>
      </Box>
      <CardActions sx={{ padding: 1 }}>
        <Button
          shape="round"
          onClick={onButtonClick}
          fullWidth
        >
          幫我內推
        </Button>
      </CardActions>
    </CardContent>
  );
};
