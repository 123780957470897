import React from 'react';
import { TextFieldProps, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FileCopyOutlined } from '@mui/icons-material';

import { BaseTextField } from '..';

const useStyles = makeStyles({
  textField: {
    width: '100%',
  },
  copyIcon: {
    '&:hover': { cursor: 'pointer' },
  },
});

type ReadonlyTextFieldProps = {
  // We only support copying string type
  value: string;
} & TextFieldProps;

export const ReadonlyTextField = ({
  value,
  ...props
}: ReadonlyTextFieldProps): React.ReactElement => {
  function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
  }
  const classes = useStyles();
  return (
    <BaseTextField
      value={value}
      type="text"
      variant="outlined"
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end" onClick={() => copyToClipboard(value)}>
            <FileCopyOutlined classes={{ root: classes.copyIcon }} />
          </InputAdornment>
        ),
      }}
      className={classes.textField}
      {...props}
    />
  );
};
