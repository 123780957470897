import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';

import { BaseModal } from './BaseModal';
import { TextAreaField, Button } from 'components';

const useStyles = makeStyles(() =>
  createStyles({
    baseModal: {
      padding: '16px 8px 24px 8px !important',
    },
    textArea: {
      width: '100%',
      maxWidth: '535px',
      minHeight: '160px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      padding: '16px 0',
    },
  })
);

type FeedbackModalProps = {
  name: string;
  open: boolean;
  onClose: () => void;
  isReject: boolean;
  onProceed: (feedback?: string) => void;
};

export const FeedbackModal = ({
  name,
  onProceed,
  isReject,
  ...props
}: FeedbackModalProps) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  return (
    <BaseModal title="提供回饋" className={classes.baseModal} {...props}>
      <h4>想跟申請人說的話</h4>
      <p>
        {isReject
          ? `Feedback is gold！給 ${name} 拒絕的回饋，幫助他/她與目標更接近。`
          : `Feedback is gold！給 ${name} 回饋，幫助他/她與目標更接近。`}
      </p>
      <TextAreaField
        className={classes.textArea}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className={classes.buttonContainer}>
        <Button color="secondary" onClick={() => onProceed()}>
          忽略回饋
        </Button>
        <Button onClick={() => onProceed(value)}>送出回饋</Button>
      </div>
    </BaseModal>
  );
};
