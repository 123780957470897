import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

export type ButtonProps = Pick<
  MuiButtonProps,
  | 'startIcon'
  | 'fullWidth'
  | 'type'
  | 'disabled'
  | 'href'
  | 'onClick'
  | 'className'
  | 'children'
  | 'sx'
> & {
  shape?: 'square' | 'round';
  color?: 'primary' | 'secondary';
};
const useStyles = makeStyles((theme) => ({
  defaultStyle: {
    height: '48px',
    fontWeight: '500',
  },
  round: {
    borderRadius: 100,
  },
}));
export const Button = ({
  children,
  color = 'primary',
  disabled,
  fullWidth,
  href,
  onClick,
  startIcon,
  shape = 'square',
  type,
  sx,
}: ButtonProps) => {
  const classes = useStyles();
  return (
    <MuiButton
      className={classes.defaultStyle}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      startIcon={startIcon}
      variant="contained"
      type={type}
      sx={{ ...sx, borderRadius: shape === 'round' ? 100 : "8px" }}
    >
      {children}
    </MuiButton>
  );
};
