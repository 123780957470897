import React from 'react';
import { ModalProps, Typography, Link } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Application } from 'types';

import { BaseModal } from './BaseModal';

import avatarImg from 'resource/avatar.png';
import emailImg from 'resource/email.svg';
import linkedinImg from 'resource/linkedin.svg';

const useStyles = makeStyles(() =>
  createStyles({
    mainInfo: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '832px',
    },

    name: {
      marginLeft: '20px',
      marginRight: 'auto',
    },
    emailIcon: {
      marginLeft: '32px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    linkedinIcon: {
      marginLeft: '32px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

type ApplicationModalProps = {
  application: Pick<
    Application,
    'firstName' | 'lastName' | 'applicantEmail' | 'linkedIn' | 'about'
  > &
    Partial<Application>;
  onClose: () => void;
} & Omit<ModalProps, 'children'>;

export const ApplicationModal: React.FC<ApplicationModalProps> = ({
  application,
  onClose,
  ...modalProps
}) => {
  const classes = useStyles();

  return (
    <BaseModal title="認識申請人" onClose={onClose} {...modalProps}>
      <div className={classes.mainInfo}>
        <img src={avatarImg} width="40" alt="Avatar icon" />
        <Typography variant="h6" className={classes.name}>
          {`${application.firstName} ${application.lastName}`}
        </Typography>
        <Link
          href={`mailto:${application.applicantEmail}`}
          target="_blank"
          rel="noopener"
          className={classes.emailIcon}
        >
          <img src={emailImg} alt="Email icon" />
        </Link>
        {application.linkedIn && (
          <Link
            href={application.linkedIn}
            target="_blank"
            rel="noopener"
            className={classes.linkedinIcon}
          >
            <img src={linkedinImg} alt="LinkedIn icon" />
          </Link>
        )}
      </div>

      {application.introduction && (
        <>
          <h4>給推薦人的話</h4>
          <p>{application.about}</p>
        </>
      )}
    </BaseModal>
  );
};
