import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Container, Snackbar, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AccountForm } from './component';
import { useAuth } from 'app/ProviderAuth';
import { Button } from 'components';
import { GoogleIcon } from 'resource';
import { auth } from 'configs/firebase';
import { useSearchParam } from 'react-use';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    '& > *': {
      margin: theme.spacing(1),
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

export const SignUpPage = () => {
  const params = useSearchParam('type');
  const { user, googleSignIn } = useAuth();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  if (user) {
    if (params === 'referrer') {
      return <Navigate to="/applications" replace />;
    }
    return <Navigate to="/companies" replace />;
  }
  const onSubmit = async (data: any) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      if (!userCredential?.user) {
        return;
      }

      sendEmailVerification(userCredential.user);
    } catch (error: any) {
      if (error instanceof Error) setErrorMessage(error?.message);
    }
  }
  const handleClose = () =>{
    setErrorMessage('');
  }
  return (
    <Box my={5}>
      <Container maxWidth="sm">
        <AccountForm
            title="註冊"
            subTitle="已有帳號？登入"
            handleSubmit={handleSubmit(onSubmit)}
            href="/signin"
            control={control}
            errors={errors}
            isSubmitting={isSubmitting}
        >
        </AccountForm>
        <Divider style={{ margin: '14px 0px 4px 0px' }} />
        <Box display="flex" justifyContent="end" className={classes.buttonRow}>
          {/* <Button
            startIcon={<FacebookIcon />}
            onClick={() =>
              fbSignIn().catch((error) => setErrorMessage(error.message))
            }
          >
            使用 Facebook 登入
          </Button> */}
          <Button
            startIcon={<GoogleIcon />}
            onClick={() =>
              googleSignIn().catch((error) => setErrorMessage(error.message))
            }
            color="secondary"
            data-cy="使用GOOGLE註冊"
          >
            使用 Google 註冊
          </Button>
        </Box>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(errorMessage)}
        onClose={handleClose}
        aria-describedby="snackbar-message"
        message={<span id="snackbar-message">{errorMessage}</span>}
        autoHideDuration={3000}
      />
    </Box>
  );
};
