import React from 'react';
import {
  TextField,
  TextFieldProps,
  InputLabel,
  FormHelperText,
  Box,
  Chip,
} from '@mui/material/index';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  helperText: {
    textAlign: 'left',
    fontSize: '16px',
    margin: '0px 0px 0px 0px',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  inputLabel: {
    fontSize: '18px',
    color: '#141921',
  },
  container: {
    padding: '0px',
  },
  touchedInputLabel: {
    textCololr: theme.palette.error.main,
  },
  innerInput: {
    padding: '20px 16px',
    fontSize: '18px',
  },
  multilineRoot: {
    padding: '0px 0px',
  },
  multiline: {
    padding: '20px 16px',
  },
  chip: {
    fontSize: '18px',
    padding: '4px 16px',
  },
}));
type BasicTextFieldProps = Omit<TextFieldProps, 'error'> & {
  touched?: boolean;
  error?: boolean;
  options?: string[];
  handleChipOnClick?: (option: string) => void;
};
/**
 * Styled base text field component built on top of mui TextField.
 */
export const BaseTextField = React.forwardRef<
  HTMLDivElement,
  BasicTextFieldProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function BaseTextField({ InputProps, className, ...props }, ref) {
    const classes = useStyles();
    const {
      required,
      touched,
      error,
      helperText,
      label,
      options,
      handleChipOnClick,
      ...cleanProps
    } = props;
    return (
      <Box display="grid" gap={1}>
        {label !== undefined && (
          <InputLabel
            required={required}
            error={required && error}
            className={clsx(
              classes.inputLabel,
              touched && classes.touchedInputLabel
            )}
            htmlFor="BaseTextField"
          >
            {label}
          </InputLabel>
        )}
        {options !== undefined && (
          <Box display="flex" gap={1}>
            {options.map((option) => (
              <Chip
                key={option}
                clickable
                label={option}
                classes={{ label: classes.chip }}
                style={{ backgroundColor: '#E5E8EE' }}
                onClick={() => handleChipOnClick!(option)}
              />
            ))}
          </Box>
        )}
        <TextField
          className={clsx(classes.container, className)}
          ref={ref}
          {...cleanProps}
          InputProps={{
            ...InputProps,
            classes: {
              input: !cleanProps.multiline ? classes.innerInput : '',
              root: cleanProps.multiline ? classes.multilineRoot : '',
              inputMultiline: classes.multiline,
            },
          }}
          label=""
          error={required && touched && error}
          variant="outlined"
        />
        {helperText !== undefined && (
          <FormHelperText
            className={`${classes.helperText} ${
              error ? classes.errorText : ''
            }`}
          >
            {touched ? error || helperText : helperText || ''}
          </FormHelperText>
        )}
      </Box>
    );
  }
);
