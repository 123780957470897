import { Timestamp } from '@firebase/firestore-types';

export const getTimeFromFireStore = (timestamp: Timestamp) => {
  const time = !timestamp ? new Date() : new Date(timestamp.seconds * 1000);
  // Nov 6
  return `${time
    .toLocaleString('default', {
      month: 'long',
    })
    .substring(0, 3)} ${time.getDate()}`;
};
