import { companiesCollection } from 'configs/db';
import {
  doc,
  DocumentReference,
  getDocs,
  getDoc,
  orderBy,
  query,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
  where,
  updateDoc,
  DocumentSnapshot,
} from 'firebase/firestore';
import { Company } from 'types';

export const companyRefById = (id: string): DocumentReference<Company> =>
  doc(companiesCollection, id);

export const queryCompanies = async (
  sortField?: string,
  direction: 'asc' | 'desc' = 'asc'
): Promise<QuerySnapshot<Company>> => {
  const q = sortField
    ? query(companiesCollection, orderBy(sortField, direction))
    : query(companiesCollection);
  return await getDocs(q);
};

export const queryCompaniesByCompany = async (
  company: string
): Promise<QuerySnapshot<Company>> =>
  await getDocs(query(companiesCollection, where('company', '==', company)));

export const getCompanyDocById = async (
  companyId: string
): Promise<DocumentSnapshot<Company>> =>
  await getDoc(doc(companiesCollection, companyId));

export const getCompanyId = async (company: string): Promise<string> => {
  const snapshots = await getDocs(
    query(companiesCollection, where('company', '==', company))
  );
  const companyId = snapshots.docs[0].id;
  return companyId;
};
export const createCompany = async (
  company: string,
  companyId: string,
  sponsorship: string
): Promise<DocumentSnapshot<Company>> => {
  await setDoc(doc(companiesCollection, companyId), {
    company: company,
    id: companyId,
    sponsorship: sponsorship,
    referrerCount: 0,
    referredApplicationCount: 0,
    rejectedApplicationCount: 0,
    reviewingApplicationCount: 0,
  });
  const compantySnapshot = await getDoc(doc(companiesCollection, companyId));
  return compantySnapshot;
};

export const updateReferrerCount = async (
  companyDoc: DocumentSnapshot<Company>
): Promise<void> => {
  const company = companyDoc.data()!;
  const data = {
    referrerCount: company.referrerCount + 1,
    updatedAt: serverTimestamp(),
  };
  await updateDoc(companyDoc.ref, data);
};

export const updateCompany = async (
  id: string,
  data: Partial<Company>
): Promise<void> => {
  await updateDoc(companyRefById(id), data);
}