import React from 'react';
import { Helmet } from 'react-helmet';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentSnapshot, serverTimestamp } from 'firebase/firestore';
import { Box, Container, Grid, Typography } from '@mui/material';

import { useAuth } from 'app/ProviderAuth';
import {
  addNewApplyActivityLog,
  getReferrer,
  putApplication,
  queryCompaniesByCompany,
  getApplicantsByUid,
} from 'apis';

import { ReferrerInfoCard } from 'components/InfoCard';

import { putFileToStorage } from 'helper/storage';

import {
  ApplicationStatus,
  ApplicationToWrite,
  Company,
  Referrer,
} from 'types';

import { ApplyForm } from './components';
import { ApplyFormFieldValues } from './ApplyPage.types';

/**
 * Apply page loads up referrer information and contains the form and business logic for submitting the application form.
 */
export const ApplyPage: React.FC = () => {
  const navigate = useNavigate();
  const { companyName, referrerId } = useParams<{
    companyName: string;
    referrerId: string;
  }>();
  const { user } = useAuth();

  const [companyToApply, setCompanyToApply] = React.useState<Company>();
  const [referrerInfo, setReferrerInfo] =
    React.useState<DocumentSnapshot<Referrer>>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = useForm<ApplyFormFieldValues>({
    defaultValues: {
      jobTitle0: '',
      jobLink0: '',
      jobId0: '',
      resume: undefined,
      coverLetter: undefined,
      portfolioUrl: '',
      lastName: '',
      firstName: '',
      email: '',
      phoneNumber: '',
      address: '',
      yearsOfExperience: 0,
      linkedIn: '',
      introduction: '',
      needSponsorship: '',
      about: '',
    },
    mode: 'onBlur',
  });

  React.useEffect(() => {
    const loadData = async () => {
      if (companyName) {
        const company = await queryCompaniesByCompany(
          decodeURIComponent(companyName)
        );
        if (company.empty || !company.docs.length) {
          return;
        }
        setCompanyToApply(company.docs.at(0)?.data());
      }

      if (referrerId) {
        const referrer = await getReferrer(referrerId);
        setReferrerInfo(referrer);
      }
    };
    loadData();
  }, [companyName, referrerId]);

  React.useEffect(() => {
    const applicantAutoFillValues = async () => {
      const defaultApplicant = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        yearsOfExperience: 0,
        linkedIn: '',
        needSponsorship: false,
      };

      if (!user) {
        return defaultApplicant;
      }

      const applicantSnapshot = await getApplicantsByUid(user.uid);
      const applicant = applicantSnapshot.data();

      return {
        ...defaultApplicant,
        ...applicant,
      };
    };

    applicantAutoFillValues().then((data) => {
      setValue('lastName', data.lastName);
      setValue('firstName', data.firstName);
      setValue('email', data.email);
      setValue('phoneNumber', data.phone);
      setValue('address', data.address);
      setValue('yearsOfExperience', data.yearsOfExperience);
      setValue('linkedIn', data.linkedIn);
      setValue('needSponsorship', data.needSponsorship.toString());
    });
  }, [setValue, user]);

  if (!user || !companyToApply || !referrerInfo) {
    return null;
  }

  const onSubmit: SubmitHandler<ApplyFormFieldValues> = async (data) => {
    if (!referrerInfo || !referrerInfo.exists()) {
      return;
    }

    let resumeFirebasePath = '';
    if (data.resume) {
      const filePath = `resume/${user.uid}/${data.resume.name}`;
      try {
        const downloadUrl = await putFileToStorage(data.resume, filePath);
        resumeFirebasePath = downloadUrl;
      } catch (e) {
        console.error('fail uploading resume');
        return;
      }
    }

    let coverLetterFirebasePath = '';
    if (data.coverLetter) {
      const filePath = `resume/${user.uid}/${data.coverLetter.name}`;
      try {
        const downloadUrl = await putFileToStorage(data.coverLetter, filePath);
        coverLetterFirebasePath = downloadUrl;
      } catch (e) {
        console.error('fail uploading cover letter');
        return;
      }
    }

    const application: ApplicationToWrite = {
      about: data.about,
      applicantUid: user.uid,
      applicantEmail: data.email,
      company: companyToApply.company,
      companyId: companyToApply.id!,
      coverLetterFirebasePath,
      createdAt: serverTimestamp(),
      firstName: data.firstName,
      introduction: data.introduction,
      jobId0: data.jobId0,
      jobLink0: data.jobLink0,
      jobTitle0: data.jobTitle0,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      linkedIn: data.linkedIn,
      needSponsorship: data.needSponsorship,
      referrerName: referrerInfo.data().name,
      referrerUid: referrerInfo.data().uid,
      resumeFirebasePath,
      status: ApplicationStatus.REVIEWING,
      statusChangeTimestamp: null,
      yearsOfExperience: data.yearsOfExperience,
    };

    try {
      await putApplication(application);
      await addNewApplyActivityLog(
        application.firstName,
        application.company,
        application.referrerName
      );
    } catch (e) {
      console.error(`fail submitting application to firebase: ${e}`);
    }

    navigate(
      `/companies/${encodeURIComponent(companyToApply.company)}/referrers/${
        referrerInfo.id
      }/apply/completed`
    );
  };

  return (
    <Container>
      <Helmet>
        <title>{`${companyName} 內推申請頁 | NEX Work`}</title>
      </Helmet>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Box display="grid" mt={4} mb={8} gap={4}>
              <Typography variant="h2">填寫內推資料</Typography>
              <ApplyForm
                key={'applyForm'}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box mt={4}>
              <Typography variant="h2">認識推薦人</Typography>
              {referrerInfo && referrerInfo.exists() && (
                <ReferrerInfoCard referrer={referrerInfo.data()} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
