import React from 'react';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    background: '#21212114',
    border: 'none',
    fontSize: '16px',
    padding: '10px 12px',
    '&:hover': {
      backgroundColor: '#dedede',
    },
    '&:active, &:focus, &:focus-visible': {
      backgroundColor: '#21212114',
      border: 'none',
    },
  },
});
/**
 * Styled test area field component built on top of mui .
 */
export const TextAreaField = React.forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function TextAreaField({ className, ...props }, ref) {
    const classes = useStyles();
    const combinedClassName = clsx(classes.root, className);
    return (
      <TextareaAutosize
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        ref={ref}
        className={combinedClassName}
        {...props}
      />
    );
  }
);
