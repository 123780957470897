import React from 'react';
import { AppBar, Link, Slide, useScrollTrigger } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useAuth } from 'app/ProviderAuth';
import { WHITE } from 'constants/colors';
import { NavbarMenu } from './NavbarMenu';

const useStyles = makeStyles((theme) => {
  return createStyles({
    appBar: {
      display: 'flex',
      flexDirection: 'row',
      padding: '12px 24px',
      backgroundColor: WHITE,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1400, // drawer: 1200, snackbar: 1400

      [theme.breakpoints.down('md')]: {
        padding: '8px 16px',
      },
    },
    toolbar: {
      display: 'flex',
      maxWidth: '1280px',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
    logoImage: {
      objectFit: 'contain',
      height: '32px',
      verticalAlign: 'middle',
    },
    mobileIcon: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  });
});

interface HideOnScrollProps {
  children: React.ReactElement;
}

function HideOnScroll({ children }: HideOnScrollProps) {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export const Navbar = () => {
  const classes = useStyles();
  const { user, isLoading, isReferrer, isAdmin } = useAuth();

  return (
    <HideOnScroll>
      <AppBar position="sticky" className={classes.appBar}>
        <div className={classes.toolbar}>
          <div>
            <Link href="/">
              <img
                alt="NEX Work"
                src="/nex-work-logo.svg"
                className={classes.logoImage}
              />
            </Link>
          </div>

          {!isLoading && (
            <NavbarMenu user={user} isReferrer={isReferrer} isAdmin={isAdmin} />
          )}
        </div>
      </AppBar>
    </HideOnScroll>
  );
};
