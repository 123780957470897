import React from 'react';
import { Controller } from 'react-hook-form';

import { BaseTextField } from 'components';

import { ControlledFieldProps } from './ControlledField.types';

/**
 * Wrapper component for BaseTextField to integrate with react-hook-form.
 */
export const ControlledField: React.FC<ControlledFieldProps<any>> = ({
  label,
  helperText,
  name,
  type,
  required,
  control,
  error,
  multiline,
  rows,
  className,
  options,
  handleChipOnClick,
}) => (
  <Controller
    name={name}
    control={control}
    rules={{ required }}
    render={({ field }) => {
      const hasError = Boolean(error);
      const helperOrErrorText =
        error?.type === 'required' ? '此欄位為必填欄位' : helperText;
      return (
        <BaseTextField
          className={className}
          fullWidth
          multiline={multiline}
          helperText={helperOrErrorText}
          type={type}
          error={hasError}
          label={label}
          required={required}
          {...field}
          rows={rows}
          options={options}
          handleChipOnClick={handleChipOnClick}
        />
      );
    }}
  />
);
