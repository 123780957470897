import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { TextField, InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

interface SearchBarProps<T> {
  data: T[];
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  sortMethod: (searchTerm: string, data: T[]) => Promise<T[]>;
  hint: string;
}
const boarderRadius = {
  borderRadius: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)',
  },
};
export const SearchBar: React.FC<SearchBarProps<any>> = ({
  data,
  setData,
  sortMethod,
  hint,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  useDebounce(
    () => {
      handleSumbit();
    },
    250,
    [searchTerm]
  );
  const handleSumbit = async () => {
    sortMethod(searchTerm, data).then((sortedData) => setData(sortedData));
  };

  return (
    <TextField
      id="input-with-icon-textfield"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon onClick={handleSumbit} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      value={searchTerm}
      onChange={({ currentTarget }) => setSearchTerm(currentTarget.value)}
      placeholder={hint}
      fullWidth
      variant="outlined"
      margin="normal"
      sx={boarderRadius}
    />
  );
};
