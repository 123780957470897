import React, { createContext, useContext, useEffect, useState } from 'react';

import { googleSignIn, fbSignIn, auth, analytics } from 'configs/firebase';
import { queryReferrersByUid } from 'apis';
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as signOutFirebase,
  User,
} from 'firebase/auth';
import { setUserId } from 'firebase/analytics';

interface AuthContext {
  user?: User;
  signIn: (email: string, password: string) => Promise<User | null>;
  signUp: (email: string, password: string) => Promise<User | null>;
  signOut: () => Promise<void>;
  googleSignIn: () => Promise<User | null>;
  fbSignIn: () => Promise<User | null>;
  isReferrer: boolean;
  setIsReferrer: (value: boolean) => void;
  isAdmin: boolean;
  isLoading: boolean;
}

export const authContext = createContext<AuthContext>({
  signIn: () => Promise.resolve(null),
  signUp: () => Promise.resolve(null),
  signOut: () => Promise.resolve(),
  googleSignIn: () => Promise.resolve(null),
  fbSignIn: () => Promise.resolve(null),
  isReferrer: false,
  setIsReferrer: () => {
    /* do nothing */
  },
  isAdmin: false,
  isLoading: false,
});

export const ProvideAuth: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = (): AuthContext => {
  return useContext(authContext);
};

const isUserReferrer = async (user: User) => {
  if (!user) {
    return false;
  }
  const querySnapshot = await queryReferrersByUid(user.uid);
  return !querySnapshot.empty;
};

const isUserAdmin = async (user: User) => {
  if (!user) {
    return false;
  }
  // forceRefresh token: true
  const idTokenResult = await user?.getIdTokenResult(true);
  return Boolean(idTokenResult.claims.admin);
};

function useProvideAuth() {
  const [user, setUser] = useState<User>();
  const [isReferrer, setIsReferrer] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const signIn = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    if (userCredential.user) {
      const user = userCredential.user;
      setUser(user);
      setIsReferrer(await isUserReferrer(user));
      setIsAdmin(await isUserAdmin(user));

      setUserId(analytics, user.uid);
    }

    return userCredential.user;
  };

  const signUp = async (email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    if (userCredential.user) {
      const user = userCredential.user;
      setUser(user);
      setIsReferrer(await isUserReferrer(user));
      setIsAdmin(await isUserAdmin(user));

      setUserId(analytics, user.uid);
    }

    return userCredential.user;
  };

  const signOut = async () => {
    await signOutFirebase(auth);
    setUser(undefined);
    setIsReferrer(false);
    setIsAdmin(false);

    setUserId(analytics, null);
  };

  const googleSignInAndSetUser = async () => {
    const userCredential = await googleSignIn();
    if (userCredential.user) {
      const user = userCredential.user;
      setUser(user);
      setIsReferrer(await isUserReferrer(user));
      setIsAdmin(await isUserAdmin(user));

      setUserId(analytics, user.uid);
    }

    return userCredential.user;
  };

  const fbSignInAndSetUser = async () => {
    const userCredential = await fbSignIn();
    if (userCredential.user) {
      const user = userCredential.user;
      setUser(user);
      setIsReferrer(await isUserReferrer(user));
      setIsAdmin(await isUserAdmin(user));

      setUserId(analytics, user.uid);
    }

    return userCredential.user;
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setIsReferrer(await isUserReferrer(user));
        setIsAdmin(await isUserAdmin(user));

        setUserId(analytics, user.uid);
      } else {
        setUser(undefined);

        setUserId(analytics, null);
      }
      setIsLoading(false);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return {
    user,
    signIn,
    signUp,
    signOut,
    googleSignIn: googleSignInAndSetUser,
    fbSignIn: fbSignInAndSetUser,
    isReferrer,
    setIsReferrer,
    isAdmin,
    isLoading,
  };
}
