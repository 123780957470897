// React
import * as React from 'react';
import { Box } from '@mui/material/index';

import { getInitializedName } from 'helper/common';

export const ApplyNudgeAction = ({ applicant, referrer, company }) => {
  return (
    <Box component="span" fontSize={14}>
      {getInitializedName(applicant) + ' 向 ' + referrer + ' 申請內推 '}
      <Box component="span" fontWeight="fontWeightBold">
        {company}
      </Box>
    </Box>
  );
};
