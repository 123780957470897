import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Referrer } from 'types';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Snackbar } from '@mui/material';

import {
  createCompany,
  createReferrer,
  updateReferrerCount,
  getCompanyDocById,
} from 'apis';

import { useAuth } from 'app/ProviderAuth';

import { Button } from 'components';
import { ReferrerForm } from '../components/ReferrerForm';

export const SignUpReferrerPage: React.FC = () => {
  const navigate = useNavigate();
  const { user, setIsReferrer, isLoading: userIsLoading } = useAuth();
  const [isNewCompany, setIsNewCompany] = useState(true);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    getValues,
  } = useForm<Referrer>({
    defaultValues: {
      name: '',
      preferredName: '',
      company: '',
      companyId: '',
      department: '',
      jobTitle: '',
      referralEmail: '',
      sponsorship: '',
      showLinkedIn: '',
      internship: '',
      linkedIn: '',
      applicantNote: '',
      location: '',
      role: '',
      careerPage: '',
    },
    mode: 'onBlur',
  });
  const onSubmit: SubmitHandler<Referrer> = async (data) => {
    if (!user) {
      return;
    }

    const referrerData: Referrer = data as Referrer;
    referrerData.company = referrerData.company.trim();
    referrerData.location = referrerData.location?.trim();
    referrerData.referralEmail = referrerData.referralEmail.trim();
    referrerData.preferredName = referrerData.preferredName
      ? referrerData.preferredName.trim()
      : referrerData.name.trim();
    await createReferrer({
      ...referrerData,
      uid: user.uid,
    });

    var companyDoc = await getCompanyDocById(referrerData.companyId);
    if (isNewCompany && !companyDoc.exists()) {
      const { company, companyId, sponsorship } = referrerData;
      companyDoc = await createCompany(company, companyId, sponsorship!);
    }

    await updateReferrerCount(companyDoc);

    setSnackBarOpen(true);
  };

  useEffect(() => {
    setValue('referralEmail', user?.email ? user?.email : '');
  }, [user, setValue]);
  if (userIsLoading) {
    return null;
  }
  const backToCompany = () => {
    setIsReferrer(true);
    navigate('/companies');
  };
  return (
    <Box my={5}>
      {/* Make this global context */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackBarOpen}
        message="成功成為推薦人"
        action={
          <Button color="secondary" onClick={backToCompany}>
            回首頁
          </Button>
        }
      />
      <Container maxWidth="md" sx={{ my: 4 }}>
        <ReferrerForm
          control={control}
          errors={errors}
          handleSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          submitted={snackBarOpen}
          setIsNewCompany={setIsNewCompany}
          setValue={setValue}
          getValues={getValues}
        />
      </Container>
    </Box>
  );
};
