import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  DialogProps,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import {
  COLOR_BLUE_BORDER,
  COLOR_LIGHT_BACKGROUND,
  COLOR_PRIMARY,
} from 'constants/colors';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      border: `1px solid ${COLOR_BLUE_BORDER}`,
      backgroundColor: COLOR_LIGHT_BACKGROUND,
      maxWidth: '960px',
      maxHeight: 'min(560px, calc(100% - 212px))',
      padding: '40px',
    },
    title: {
      color: COLOR_PRIMARY,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: 700,
    },
    icon: {
      backgroundColor: COLOR_PRIMARY,
      position: 'relative',
      width: '40px',
      height: '40px',
      '&:hover': {
        cursor: 'pointer',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(135deg)',
        width: '30px',
        height: '2px',
        backgroundColor: '#FFFF',
        borderRadius: '1px',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        width: '30px',
        height: '2px',
        backgroundColor: '#FFFF',
        borderRadius: '1px',
      },
    },
    content: {
      '&::-webkit-scrollbar-thumb': {
        background: '#CDE0F1',
      },
      '&::-webkit-scrollbar': {
        width: '8px',
      },
    },
    text: {
      color: '#000',
    },
  })
);

interface BaseModalProps extends DialogProps {
  title: string;
  onClose: () => void;
}

export const BaseModal = ({
  title,
  onClose,
  children,
  className,
  ...modalProps
}: BaseModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={() => {
        return null;
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{ paper: `${classes.paper} ${className}` }}
      {...modalProps}
    >
      <DialogTitle classes={{ root: classes.title }}>
        <Typography variant="h2">{title}</Typography>
        <div className={classes.icon} onClick={onClose} />
      </DialogTitle>

      <DialogContent classes={{ root: classes.content }}>
        <DialogContentText className={classes.text}>
          {children}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
