/* eslint-disable @typescript-eslint/no-unused-vars */
const BLUE_50 = '#f1fbff';
const BLUE_100 = '#d2f3fe';
const BLUE_200 = '#bdedfe';
export const BLUE_300 = '#9fe4fe';
const BLUE_400 = '#8cdffd';
const BLUE_500 = '#6fd7fd';
const BLUE_600 = '#65c4e6';
const BLUE_700 = '#4f99b4';
const BLUE_800 = '#3d768b';
const BLUE_900 = '#2f5a6a';

const GREEN_50 = '#edfcf2';
export const GREEN_100 = '#c6f5d5';
const GREEN_200 = '#aaf0c1';
const GREEN_300 = '#84e9a5';
const GREEN_400 = '#6ce593';
const GREEN_500 = '#47de78';
const GREEN_600 = '#41ca6d';
const GREEN_700 = '#329e55';
const GREEN_800 = '#277a42';
const GREEN_900 = '#1e5d32';

export const WHITE = '#FFFFFF';
export const GREY8 = '#F4F6FA';
export const GREY7 = '#E5E8EE';
export const GREY6 = '#C6CBD3';
export const GREY5 = '#ABB0B9';
export const GREY4 = '#6F7682';
export const GREY3 = '#4D5461';
const GREY2 = '#2C333D';
export const GREY1 = '#141921';
const GREY0 = '#121218';
export const BLACK = '#000000';

export const COLOR_PRIMARY = BLUE_500;
export const COLOR_SECONDARY = '#F9AB48';

export const COLOR_LIGHT_BACKGROUND = '#F7FAFD';
export const COLOR_BLUE_BORDER = 'rgba(48, 127, 196, 0.5)';

export const COLOR_TEXT_PRIMARY = '#25263a';

export const COLOR_INTERFACE_BLUE = '#002761';

export const COLOR_PAGE_BACKGROUND = '#fbfcfd';
