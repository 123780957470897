import { storage } from 'configs/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export const putFileToStorage = async (file: File, path: string) => {
  const storageRef = ref(storage, path);
  try {
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  } catch (e) {
    console.warn(e);
  }

  return '';
};
