import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Container, Snackbar, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AccountForm } from './component';
import { useAuth } from 'app/ProviderAuth';
import { Button } from 'components';
import { GoogleIcon } from 'resource';
const useStyles = makeStyles((theme) => ({
  buttonRow: {
    '& > *': {
      margin: theme.spacing(1),
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

export const SignInPage = () => {
  const { user, signIn, googleSignIn } = useAuth();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  if (user) {
    return <Navigate to="/companies" replace />;
  }
  const onSubmit = async (data: any) => {
    try {
      await signIn(data.email, data.password);
    } catch (e: any) {
      setErrorMessage(e.message);
    }
  }
  const handleClose = () =>{
    setErrorMessage('');
  }
  return (
    <Box my={5}>
      <Container maxWidth="sm">
        <AccountForm
            title="登入"
            subTitle="忘記密碼？"
            handleSubmit={handleSubmit(onSubmit)}
            href="forgot/password"
            control={control}
            errors={errors}
            isSubmitting={isSubmitting}
        >
        </AccountForm>
        <Divider style={{ margin: '14px 0px 4px 0px' }} />
        <Box display="flex" justifyContent="end" className={classes.buttonRow}>
          {/* <Button
            startIcon={<FacebookIcon />}
            onClick={() =>
              fbSignIn().catch((error) => setErrorMessage(error.message))
            }
          >
            使用 Facebook 登入
          </Button> */}
          <Button
            startIcon={<GoogleIcon />}
            onClick={() =>
              googleSignIn().catch((error) => setErrorMessage(error.message))
            }
            color="secondary"
          >
            使用 Google 登入
          </Button>
        </Box>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(errorMessage)}
        onClose={handleClose}
        aria-describedby="snackbar-message"
        message={<span id="snackbar-message">{errorMessage}</span>}
        autoHideDuration={3000}
      />
    </Box>
  );
};
