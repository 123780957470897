import React from 'react';

import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { BaseTextField } from 'components';
import {
  ReferrerAutocompleteProps,
  CompanyOptions,
} from './ReferrerForm.types';
import { Controller } from 'react-hook-form';
import { uuidv4 } from '@firebase/util';

export const CompanyAutocomplete: React.FC<ReferrerAutocompleteProps<any>> = ({
  label,
  name,
  companyOptions,
  control,
  error,
  setValue,
  setIsNewCompany,
}) => {
  const filter = createFilterOptions<CompanyOptions>();

  // Once db is updated, remove this function
  const getUniqueOptions = (options: CompanyOptions[]) => {
    const uniqueTitles: string[] = [];
    const uniqueOptions: CompanyOptions[] = [];
    options.forEach((option) => {
      if (!uniqueTitles.includes(option.title)) {
        uniqueTitles.push(option.title);
        uniqueOptions.push(option);
      }
    });
    return uniqueOptions;
  };
  const handleOnChange = (
    _: React.ChangeEvent<{}>,
    value: CompanyOptions | string
  ) => {
    const company = typeof value === 'string' ? value : value.inputValue;
    const existedCompany = companyOptions.find(
      (option) => option.inputValue.toLowerCase() === company.toLowerCase()
    );
    const id = existedCompany ? existedCompany.id : uuidv4();
    if (!existedCompany) {
      setIsNewCompany(true);
    }
    setValue('company', company);
    setValue('companyId', id);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          id="autoComplete"
          freeSolo
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableClearable
          options={getUniqueOptions(companyOptions)}
          onChange={handleOnChange}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.title
          }
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (
              params.inputValue !== '' &&
              !options.some(
                (option) =>
                  option.inputValue.toLowerCase() ===
                  params.inputValue.toLowerCase()
              )
            ) {
              filtered.push({
                inputValue: params.inputValue,
                title: `新增 "${params.inputValue}"`,
                id: '',
              });
            }
            return filtered;
          }}
          renderInput={(params) => {
            const hasError = Boolean(error);
            const helperOrErrorText = hasError ? '此欄位為必填欄位' : '';
            return (
              <BaseTextField
                helperText={helperOrErrorText}
                error={hasError}
                {...params}
                label={label}
                fullWidth
                required
              />
            );
          }}
        />
      )}
    />
  );
};
