import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { queryApplications, queryReferrers } from 'apis';

import { useAuth } from 'app/ProviderAuth';

import { Button } from 'components';
import { Application } from 'types';
import { getDisplayName } from 'helper/application';

type AdminApplicationRow = Application & { id: string; referralEmail: string };

export const AdminApplicationsPage = () => {
  const { isLoading: userIsLoading, isAdmin } = useAuth();
  const [applications, setApplications] = useState<AdminApplicationRow[]>([]);

  useEffect(() => {
    if (!userIsLoading && isAdmin) {
      const fetchData = async () => {
        // Creating referrer mappings: { referrer.uid: referrer.referralEmail }
        const referrersEmailInfo: Map<string, string> = new Map();
        const referrersSnapshot = await queryReferrers();
        referrersSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          referrersEmailInfo.set(data.uid, data.referralEmail);
        });

        const applicationsSnapshot = await queryApplications(
          'createdAt',
          'desc'
        );
        const augmentedApplications: AdminApplicationRow[] = [];
        applicationsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          augmentedApplications.push({
            ...data,
            id: doc.id,
            referralEmail: referrersEmailInfo.get(data.referrerUid) || '',
          });
        });
        setApplications(augmentedApplications);
      };

      fetchData();
    }
  }, [userIsLoading, isAdmin]);

  const getCSVFromTable = () => {
    const csv = [
      // Header row
      'Applicant Name, Applicant UID, Applicant Email, Apply Time, Referred Time, Company, Referrer Name, Referrer Email, Status, Feedback',
    ];

    function* generateFields(application: AdminApplicationRow) {
      yield getDisplayName(application);
      yield application.applicantUid;
      yield application.applicantEmail;
      yield application.createdAt?.toDate().toLocaleString();
      yield application.statusChangeTimestamp?.toDate().toLocaleString();
      yield application.company;
      yield application.referrerName;
      yield application.referralEmail;
      yield application.status;
      yield application.referrerFeedback;
    }

    for (const application of applications) {
      const row = [];
      const applicationFields = generateFields(application);

      let field = applicationFields.next();
      while (!field.done) {
        row.push(field.value?.replaceAll(/[，,\n]/g, ' '));
        field = applicationFields.next();
      }

      csv.push(row.join(','));
    }

    return csv.join('\n');
  };

  const downloadCSV = () => {
    const csv = getCSVFromTable();
    const csvFile = new Blob(["\ufeff" + csv], { type: 'text/csv;charset=utf-8;' });

    const downloadLink = document.createElement('a');
    downloadLink.download = 'applications.csv';
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const renderApplication = (application: AdminApplicationRow) => {
    return (
      <TableRow key={application.id}>
        <TableCell>{getDisplayName(application)}</TableCell>
        <TableCell>{application.applicantUid}</TableCell>
        <TableCell>{application.applicantEmail}</TableCell>
        <TableCell>
          {application.createdAt?.toDate().toLocaleString()}
        </TableCell>
        <TableCell>
          {application.statusChangeTimestamp?.toDate().toLocaleString()}
        </TableCell>
        <TableCell>{application.company}</TableCell>
        <TableCell>{application.referrerName}</TableCell>
        <TableCell>{application.referralEmail}</TableCell>
        <TableCell>{application.status}</TableCell>
        <TableCell>{application.referrerFeedback}</TableCell>
      </TableRow>
    );
  };

  const ToolBar = () => {
    return (
      <Box py={2} display="flex" width="100%" justifyContent="flex-end">
        <Button onClick={downloadCSV}>下載 CSV</Button>
      </Box>
    );
  };

  const ApplicationTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Applicant Name</TableCell>
              <TableCell>Applicant UID</TableCell>
              <TableCell>Applicant Email</TableCell>
              <TableCell>Apply Time</TableCell>
              <TableCell>Referrer Response Time</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Referrer Name</TableCell>
              <TableCell>Referrer Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{applications.map(renderApplication)}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (userIsLoading) {
    return null;
  }

  if (!isAdmin) {
    return <Navigate to="/companies" replace />;
  }

  return (
    <Box mx="8%">
      <ToolBar />
      <ApplicationTable />
    </Box>
  );
};
