import React from 'react';
import { Card } from '@mui/material';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  baseInfoCard: {
    padding: '32px',
    backgroundColor: theme.palette.info.main,
    borderColor: theme.palette.info.dark,
  },
}));

interface BaseInfoCardProps {
  children?: React.ReactNode;
  className?: string;
}

export const BaseInfoCard = ({ className, children }: BaseInfoCardProps) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.baseInfoCard, className)} variant="outlined">
      {children}
    </Card>
  );
};
