import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { Box } from '@mui/material';

import { Footer, Navbar } from 'components';

import themeV2 from 'helper/themeV2';

import { ProvideAuth } from './ProviderAuth';
import { ProviderFooter } from 'hooks/useFooter';

/* Pages */
import { AdminHomePage } from 'views/admin/AdminHomePage';
import { AdminApplicationsPage } from 'views/admin/AdminApplicationsPage';
import { AdminReferrersPage } from 'views/admin/AdminReferrersPage';
import { AdminCompaniesPage } from 'views/admin/AdminCompaniesPage';
import { AdminUpdateCompanyPage } from 'views/admin/AdminUpdateCompanyPage';

import { SignInPage } from 'views/auth/SignInPage';
import { SignUpPage } from 'views/auth/SignUpPage';
import { SignOutPage } from 'views/auth/SignOutPage';
import { ForgotPasswordPage } from 'views/ForgotPasswordPage';
import { ConversationPage } from 'views/ConversationPage';

import { CompaniesListPage, CompanyPage } from 'views/companies';
import { ApplyCompletedPage, ApplyPage } from 'views/companies/referrers';

import { SignUpReferrerPage } from 'views/Profiles/Referrers/SignUpReferrerPage';
import { EditProfilePage } from 'views/Profiles/EditProfilePage';

import {
  ApplicationsPage,
  ApplicationDetailPage,
  ReferCompletedPage,
} from 'views/applications';
import { ApplicationsSentPage } from 'views/applications/sent';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const App: React.FC = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>台灣第一內推平台 | NEX Work</title>
        <meta
          name="description"
          content="NEX Work 是一個非營利線上求職內推平台，連結世界各地菁英，創造團結互助的力量，讓台灣人陪著台灣人在國際職涯路上打開第一扇窗或衝刺最後一哩路。"
        />
        <link rel="canonical" href="https://work.nexf.org/" />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeV2}>
          <ProvideAuth>
            <ProviderFooter>
              <Box
                minHeight={'100vh'}
                display="flex"
                flexDirection="column"
                sx={{ bgcolor: 'background.default' }}
              >
                <Box flexGrow={1}>
                  <Navbar />
                  <Routes>
                    <Route path="companies" element={<Outlet />}>
                      <Route index element={<CompaniesListPage />} />
                      <Route path=":companyName" element={<CompanyPage />} />
                      <Route
                        path=":companyName/referrers/:referrerId/apply"
                        element={<ApplyPage />}
                      />
                      <Route
                        path=":companyName/referrers/:referrerId/apply/completed"
                        element={<ApplyCompletedPage />}
                      />
                    </Route>
                    <Route path="/edit/profile" element={<EditProfilePage />} />

                    <Route path="applications" element={<Outlet />}>
                      <Route index element={<ApplicationsPage />} />
                      <Route path="sent" element={<ApplicationsSentPage />} />
                      <Route
                        path=":applicationId"
                        element={<ApplicationDetailPage />}
                      />
                      <Route
                        path=":applicationId/completed"
                        element={<ReferCompletedPage />}
                      />
                    </Route>
                    <Route
                      path="application-status"
                      element={<ApplicationsSentPage />}
                    />

                    <Route path="admin" element={<Outlet />}>
                      <Route index element={<AdminHomePage />} />
                      <Route
                        path="applications"
                        element={<AdminApplicationsPage />}
                      />
                      <Route
                        path="referrers"
                        element={<AdminReferrersPage />}
                      />
                      <Route
                        path="companies"
                        element={<AdminCompaniesPage />}
                      />
                      <Route
                        path="companies/:id"
                        element={<AdminUpdateCompanyPage />}
                      />
                    </Route>

                    <Route path="signout" element={<SignOutPage />} />
                    <Route path="signin" element={<SignInPage />} />
                    <Route path="signup" element={<SignUpPage />} />
                    <Route
                      path="signup/referrer"
                      element={<SignUpReferrerPage />}
                    />
                    <Route
                      path="forgot/password"
                      element={<ForgotPasswordPage />}
                    />
                    <Route path="messages" element={<ConversationPage />} />
                  </Routes>
                </Box>
                <Footer />
              </Box>
            </ProviderFooter>
          </ProvideAuth>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
