import { createTheme } from '@mui/material/styles';
import {
  BLUE_300,
  COLOR_BLUE_BORDER,
  COLOR_INTERFACE_BLUE,
  COLOR_LIGHT_BACKGROUND,
  COLOR_PAGE_BACKGROUND,
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TEXT_PRIMARY,
  GREEN_100,
  GREY5,
  GREY6,
  GREY7,
  GREY8,
} from 'constants/colors';

// import NotoSans from './fonts/NotoSans';

const themeV2 = createTheme({
  typography: {
    fontFamily: ['Noto Sans TC', 'sans-serif'].join(','),
    h1: {
      fontSize: 32,
      fontWeight: 'bold',
      lineHeight: 1.25,
    },
    h2: {
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.25,
    },
    h3: {
      fontSize: 22,
      fontWeight: 'bold',
      lineHeight: 1.25,
    },
    body1: {
      fontSize: 18,
      fontWeight: 'normal',
      lineHeight: 1.4,
    },
    body2: {
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 1.4,
    },
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: { main: COLOR_SECONDARY },
    info: {
      main: COLOR_LIGHT_BACKGROUND,
      dark: COLOR_BLUE_BORDER,
    },
    text: {
      primary: COLOR_TEXT_PRIMARY,
    },
    background: { default: COLOR_PAGE_BACKGROUND },
  },

  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '10px 24px',
          color: COLOR_INTERFACE_BLUE,
          fontSize: 20,
          fontWeight: 'normal',
          lineHeight: '28px',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          background: `linear-gradient(96.53deg, ${BLUE_300} 0%, ${GREEN_100} 95.17%)`,

          '&:hover': {
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(96.53deg, ${BLUE_300} 0%, ${GREEN_100} 95.17%)`,
          },

          '&:disabled': {
            background: GREY5,
            color: GREY6,
          },
        },
        containedSecondary: {
          background: GREY7,

          '&:hover': {
            background: GREY8,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

export default themeV2;
