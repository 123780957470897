import { activityLogsCollection } from 'configs/db';
import {
  addDoc,
  DocumentReference,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
} from 'firebase/firestore';
import { ActivityLogAction, ActivityLogs } from 'types';

export const getActivityTop5Logs = async (): Promise<ActivityLogs[]> => {
  const querySnapshot = await getDocs(
    query(activityLogsCollection, orderBy('timestamp', 'desc'), limit(5))
  );

  const logs: ActivityLogs[] = [];
  querySnapshot.forEach((doc) => {
    logs.push(doc.data());
  });
  return logs;
};

export const addNewApplyActivityLog = async (
  applicantName: string,
  companyName: string,
  referrerName: string
): Promise<DocumentReference> => {
  const activityLog = {
    action: ActivityLogAction.APPLY,
    applicant: applicantName,
    company: companyName,
    referrer: referrerName,
    timestamp: serverTimestamp(),
  };
  return await addDoc(activityLogsCollection, activityLog);
};

export const addNewReferActivityLog = async (
  applicantName: string,
  companyName: string,
  referrerName: string
): Promise<DocumentReference> => {
  const activityLog = {
    action: ActivityLogAction.REFER,
    applicant: applicantName,
    company: companyName,
    referrer: referrerName,
    timestamp: serverTimestamp(),
  };
  return await addDoc(activityLogsCollection, activityLog);
};
