import React, { ReactNode, FormEvent } from 'react';
import { Box } from '@mui/material';

type NexFormProps = {
  handleSubmit: (event: FormEvent) => void;
  gap: number;
  children: ReactNode;
};
export class NexForm extends React.Component<NexFormProps> {
  render() {
    const { handleSubmit, gap, children } = this.props;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Box display="grid" gap={gap}>
          {React.Children.map(children, (child, index) => (
            <div key={index}> {child}</div>
          ))}
        </Box>
      </form>
    );
  }
}
