import React from 'react';
import { Controller } from 'react-hook-form';

import { UploadFile } from 'components';

import { ControlledUploadFileProps } from './ControlledUploadFile.types';

/**
 * Wrapper component for UploadFile to integrate with react-hook-form.
 */
export const ControlledUploadFile: React.FC<ControlledUploadFileProps> = ({
  label,
  helperText,
  name,
  required,
  control,
  error,
  accept,
}) => (
  <Controller
    name={name}
    control={control}
    rules={{ required }}
    render={({ field }) => {
      const hasError = Boolean(error);
      const helperOrErrorText =
        error?.type === 'required' ? '此欄位為必填欄位' : helperText;
      return (
        <UploadFile
          label={label}
          helperText={helperOrErrorText}
          error={hasError}
          accept={accept}
          onFileChange={(file) => {
            field.onChange(file);
          }}
        />
      );
    }}
  />
);
