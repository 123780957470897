import React from 'react';
import { useToggle } from 'react-use';

import { User } from 'firebase/auth';
import { Box, Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { NavbarButton } from './NavbarButton';

interface NavbarMenuProps {
  user?: User;
  isReferrer: boolean;
  isAdmin: boolean;
}

const getLinks = (isReferrer: boolean, isAdmin: boolean, user?: User) => {
  const links = [{ href: '/companies', children: '內推公司列表' }];
  if (!user || !isReferrer) {
    links.push({ href: '/signup?type=referrer', children: '成為推薦人' });
  }

  if (!user) {
    links.push({ href: '/signup', children: '幫我內推' });
  }

  if (user) {
    links.push({ href: '/applications/sent', children: '別人推我' });

    if (isReferrer) {
      links.push({ href: '/applications', children: '我推別人' });
    }

    links.push({ href: '/edit/profile', children: '編輯個人資料' });
  }

  // if (user) {
  //   links.push({ href: '/messages', children: '聊天室' });
  // }

  if (isAdmin) {
    links.push({ href: '/admin', children: '後台' });
  }

  if (!user) {
    links.push({ href: '/signup', children: '註冊/登入' });
  }

  if (user) {
    links.push({ href: '/signout', children: '登出' });
  }

  return links;
};

export const NavbarMenu = ({ user, isReferrer, isAdmin }: NavbarMenuProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useToggle(false);

  const handleMenuToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const links = getLinks(isReferrer, isAdmin, user);

  return (
    <>
      {/* mobile */}
      <IconButton
        edge="end"
        aria-label="menu"
        onClick={handleMenuToggle}
        size="large"
        sx={{
          display: { xs: 'flex', md: 'none' },
          padding: '4px',
          marginRight: '-4px',
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={handleMenuToggle}
        BackdropProps={{ invisible: true }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '4px',
            margin: '60px 4px 4px 4px',
          }}
        >
          {links.map(({ href, children }) => (
            <NavbarButton href={href} key={`navbar-button-${children}`}>
              {children}
            </NavbarButton>
          ))}
        </Box>
      </Drawer>

      {/* desktop */}
      <Box
        component="nav"
        sx={{ display: { xs: 'none', md: 'flex' }, columnGap: '8px' }}
      >
        {links.map(({ href, children }) => (
          <NavbarButton href={href} key={`navbar-button-${children}`}>
            {children}
          </NavbarButton>
        ))}
      </Box>
    </>
  );
};
