import React, {useState} from 'react';
import { Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Snackbar, Container } from '@mui/material';

import { useAuth } from 'app/ProviderAuth';
import { auth } from 'configs/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';

export const ForgotPasswordPage = () => {
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  if (user) {
    return <Navigate to="/companies" replace />;
  }
  const onSubmit = async (data: any) => { 
    try {
      await sendPasswordResetEmail(auth, data.email);
      setErrorMessage('Email sent.');
    } catch (e) {
      setErrorMessage('An error happened.');
    }
  }
  function handleClose() {
    setErrorMessage('');
  }
  return (
    <Box my={5}>
      <Container maxWidth="sm">
        <ForgotPasswordForm 
          control={control}
          errors={errors}
          handleSubmit={handleSubmit(onSubmit)}        
          isSubmitting={isSubmitting}
        />
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(errorMessage)}
        onClose={handleClose}
        aria-describedby="snackbar-message"
        message={<span id="snackbar-message">{errorMessage}</span>}
        autoHideDuration={3000}
      />
    </Box>
  );
};
