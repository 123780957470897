import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getDocs,
  QueryDocumentSnapshot,
  serverTimestamp,
} from 'firebase/firestore';

import {
  Box,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { getApplicationsQuery, updateApplication } from 'apis';
import { useAuth } from 'app/ProviderAuth';
import { Application, ApplicationStatus } from 'types';

import { ApplicationDetailCard } from './components/ApplicationDetailCard';
import FeedbackDialog from './components/FeedbackDialogLegacy';

export const ApplicationsPage = () => {
  const { user, isLoading, isReferrer } = useAuth();
  const navigate = useNavigate();

  const [status, setStatus] = useState<ApplicationStatus>(
    ApplicationStatus.REVIEWING
  );
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [targetId, setTargetId] = useState('');

  const [applicationsDocs, setApplicationsDocs] = useState<
    QueryDocumentSnapshot<Application>[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const q = getApplicationsQuery({
          referrerUid: user.uid,
          status,
          sortField: 'createdAt',
        });

        const apps = await getDocs(q);
        const sortedAppsDocs = apps.docs.sort((a, b) => {
          const dateA = a.data().createdAt?.toDate().getTime();
          const dateB = b.data().createdAt?.toDate().getTime();
          if (!dateA || !dateB) return 0;
          if (sortOrder === 'asc') return dateA - dateB;
          return dateB - dateA;
        });

        setApplicationsDocs(sortedAppsDocs);
      }
    };

    fetchData();
  }, [user, status, sortOrder]);

  if (isLoading) {
    return null;
  }

  if (!user) {
    navigate('/signin', { replace: true });
    return null;
  }

  if (!isReferrer) {
    navigate('/signup/referrer', { replace: true });
    return null;
  }

  const renderApplication = (doc: QueryDocumentSnapshot<Application>) => (
    <Grid item xs={12} key={`${doc.id}`}>
      <ApplicationDetailCard
        id={doc.id}
        application={doc.data()}
        onDetailButtonClick={(applicationId) => {
          navigate(`/applications/${applicationId}`);
        }}
        onRejectButtonClick={(applicationId) => {
          setRejectDialogOpen(true);
          setTargetId(applicationId);
        }}
      />
    </Grid>
  );

  const handleReject = async (id: string, referrerFeedback: string) => {
    await updateApplication(id, {
      status: ApplicationStatus.REJECTED,
      referrerFeedback,
      statusChangeTimestamp: serverTimestamp(),
    });
    setRejectDialogOpen(false);
  };

  return (
    <Box sx={{ margin: '40px auto' }}>
      <FeedbackDialog
        title="是否確認要取消此內推申請？"
        content="取消此內推申請後，該申請人會收到通知。"
        onConfirm={(feedback: string) => handleReject(targetId, feedback)}
        onClose={() => {
          setRejectDialogOpen(false);
        }}
        open={rejectDialogOpen}
      />
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={8} xs={12}>
            <Typography variant="h2">我推別人</Typography>
          </Grid>
          <Grid item lg={2} xs={6}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value as ApplicationStatus)}
              >
                <MenuItem value={ApplicationStatus.REVIEWING}>待處理</MenuItem>
                <MenuItem value={ApplicationStatus.REFERRED}>已推薦</MenuItem>
                <MenuItem value={ApplicationStatus.REJECTED}>已拒絕</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={2} xs={6}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <Select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value as 'asc' | 'desc')}
              >
                <MenuItem value="desc">時間 舊 - 新</MenuItem>
                <MenuItem value="asc">時間 新 - 舊</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {!applicationsDocs.length ? (
          <Box sx={{ textAlign: 'center', my: 8 }}>
            <Typography>目前沒有內推申請</Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {applicationsDocs.map(renderApplication)}
          </Grid>
        )}
      </Container>
    </Box>
  );
};
