import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button, ControlledField, NexForm } from 'components';
import {
  Control,
  FieldErrors,
} from 'react-hook-form';
import { FormEvent } from 'react';

type account = {
  email: string;
  password: string;
}

export interface AccountFormProps {
  title: string;
  subTitle: string;
  control: Control<account>;
  errors: FieldErrors<account>;
  handleSubmit: (event: FormEvent) => void;
  isSubmitting: boolean;
  href: string;
}
export const AccountForm: React.FC<AccountFormProps> = ({
  title,
  subTitle,
  control,
  errors,
  handleSubmit,
  isSubmitting,
  href,
}) => {
  return (
  <NexForm gap={1} handleSubmit={handleSubmit}>
    <Box>
      <Typography component="h1" variant="h5">{title}</Typography>
    </Box>
    <Box display="grid" gap={2.5}>
      <ControlledField
        label="Email"
        name="email"
        control={control}
        required
        error={errors.email}
      />
      <ControlledField
        label="Password"
        name="password"
        control={control}
        required
        type='password'
        error={errors.password}
      />
    </Box>
    <Box display="flex" justifyContent="flex-end" gap={2}>
      <Button color="secondary" href={href}>
        {subTitle}
      </Button>
      <Button type="submit" disabled={isSubmitting}>
        {title}
      </Button>
    </Box>
  </NexForm>
  );
}