import React from 'react';
import { useAuth } from 'app/ProviderAuth';
import { Navigate } from 'react-router-dom';

export const SignOutPage = () => {
  const { isLoading, signOut } = useAuth();

  if (isLoading) {
    return null;
  }
  signOut();
  return <Navigate to="/companies" replace />;
};
