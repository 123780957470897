import * as React from 'react';
import {
  TextField,
  TextFieldProps,
  InputLabel,
  FormHelperText,
  Container,
} from '@mui/material/index';
import makeStyles from '@mui/styles/makeStyles';

type NexTextFieldProps = Omit<TextFieldProps, 'error'> & {
  touched?: boolean;
  error?: string;
};
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '16px',
  },
  helperText: {
    textAlign: 'left',
    fontSize: '16px',
    margin: '0px 0px 0px 0px',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  inputLabel: {
    fontSize: '18px',
    color: '#141921',
  },
  container: {
    paddingBottom: '24px',
  },
}));

export const NexTextField = (props: NexTextFieldProps) => {
  const { required, touched, error, helperText, style, ...cleanProps } = props;
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <InputLabel
        required={required}
        error={required && touched && Boolean(error)}
        className={classes.inputLabel}
        htmlFor="NextTextField"
      >
        {cleanProps.label}
      </InputLabel>
      <TextField
        {...cleanProps}
        fullWidth
        label=""
        required={required}
        error={required && touched && Boolean(error)}
        style={{
          ...style,
          marginBottom: helperText === undefined ? '8px' : '0px',
          marginTop: '8px',
        }}
      />
      {helperText !== undefined && (
        <FormHelperText
          className={`${classes.helperText} ${error ? classes.errorText : ''}`}
        >
          {touched ? error || helperText : helperText || ''}
        </FormHelperText>
      )}
    </Container>
  );
};
