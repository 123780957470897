import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Box, Container, Snackbar } from '@mui/material';
import { createOrUpdateApplicant, getApplicantsByUid } from 'apis';
import { useAuth } from 'app/ProviderAuth';
import { Button } from 'components';
import { ApplicantForm } from './components/ApplicantForm';
import { Applicant } from 'types';

export const EditApplicantProfilePage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [userDocID, setUserDocID] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = useForm<Applicant>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      linkedIn: '',
      needSponsorship: '',
    },
    mode: 'onBlur',
  });
  
  useEffect(() => {
    if (user) {
      const fetchData = async function () {
        const applicantSnapshot = await getApplicantsByUid(user.uid);
        setUserDocID(user.uid);
        const applicant = applicantSnapshot.data();
        setValue('email', user!.email ?? '');
        // check if this user has setup applicant profile before.
        if (applicant !== undefined) {
          setValue('firstName', applicant.firstName);
          setValue('lastName', applicant.lastName);
          setValue('email', applicant.email ?? user.email);
          setValue('phone', applicant.phone ?? '');
          setValue('address', applicant.address ?? '');
          setValue('linkedIn', applicant.linkedIn ?? '');
          setValue('needSponsorship', applicant.needSponsorship ?? '');
        } 
      };
      fetchData();
    }
  }, [user, setValue]);
  const onSubmit: SubmitHandler<Applicant> = async (data) => {
    const applicantData: Applicant = data as Applicant;
    await createOrUpdateApplicant(userDocID, applicantData);
    setSnackBarOpen(true);
  };
  const backToCompany = () => {
    navigate('/companies');
  };
  return (
    <Box my={5}>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={snackBarOpen}
      message="成功更新申請者資料"
      action={
        <Button color="secondary" onClick={backToCompany}>
          回首頁
        </Button>
      }
    />
    <Container maxWidth="md" sx={{ my: 4 }}>
      <ApplicantForm
        control={control}
        errors={errors}
        isSubmitting={isSubmitting}
        submitted={snackBarOpen}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </Container>
    </Box>
  );
};
