import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Container, Snackbar } from '@mui/material';
import { Button, ControlledField, NexForm } from 'components';
import { getCompanyDocById, updateCompany } from 'apis';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { useAuth } from 'app/ProviderAuth';


export function AdminUpdateCompanyPage() {
  const navigate = useNavigate();
  const { isLoading: userIsLoading, isAdmin } = useAuth();
  const { id } = useParams();
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const { 
    control, 
    handleSubmit, 
    formState: {isSubmitting, errors},
    setValue,
  } = useForm({
    defaultValues: {
      company: '',
      logoUrl: '',
    },
  });
  useEffect(() => {
    async function fetchData() {
      const doc = await getCompanyDocById(id!);
      const values = doc.data();
      setValue('company', values?.company!);
      setValue('logoUrl', values?.logoUrl ?? '');
    }
    fetchData();
  }, [id, setValue]);

  if (userIsLoading) {
    return null;
  }

  if (!isAdmin) {
    return <Navigate to="/companies" replace />;
  }
  const onSubmit = async (values: any) => {
    await updateCompany(id!, values);
    setSnackBarOpen(true);
  };
  const backToAdmin = () => {
    navigate('/admin');
  };
  return (
    <Box my={5}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackBarOpen}
        message="成功更新公司資料"
        action={
          <Button color="secondary" onClick={backToAdmin}>
            回後台首頁
          </Button>
        }
      />
      <Container maxWidth="sm">
        <NexForm gap={1} handleSubmit= {handleSubmit(onSubmit)}>
          <Box display="grid" gap={2.5}>
            <ControlledField
              label="公司名稱"
              name="company"
              control={control}
              required
              error={errors.company}
            />
            <ControlledField
              name="logoUrl"
              label="Logo 連結"
              control={control}
              error={errors.company}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" disabled={isSubmitting}>
              送出
            </Button>
          </Box>
        </NexForm>
      </Container>
    </Box>
  );
}
