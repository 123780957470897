import { Company } from 'types';
const countCommonCharacters = (string1: String, string2: String): number => {
  let count = 0;
  const charCount: { [char: string]: number } = {};
  for (const char of string1) {
    charCount[char] = (charCount[char] || 0) + 1;
  }
  for (const char of string2) {
    if (charCount[char] > 0) {
      count++;
      charCount[char]--;
    }
  }
  return count;
};
const similarityScore = (company: string, searchQuery: string): number => {
  company = company.toLowerCase();
  searchQuery = searchQuery.toLowerCase();
  let score = 0;
  const minLen = Math.min(company.length, searchQuery.length);

  for (let i = 0; i < minLen; i++) {
    if (company[i] === searchQuery[i]) {
      score += 10;
    } else {
      break;
    }
  }
  score += countCommonCharacters(searchQuery, company);
  return company.includes(searchQuery)
    ? score + 10 * searchQuery.length
    : score;
};
export const sortByStringSimilarity = async (
  searchString: string,
  companies: Company[]
): Promise<Company[]> => {
  const sortedCompanies = [...companies];
  if (searchString.trim() !== '') {
    sortedCompanies.sort(
      (a, b) =>
        similarityScore(b.company, searchString) -
        similarityScore(a.company, searchString)
    );
  } else {
    sortedCompanies.sort((a, b) => {
      if (a.company < b.company) {
        return -1;
      }
      if (a.company > b.company) {
        return 1;
      }
      return 0;
    });
  }
  return sortedCompanies;
};
