import React, { useEffect, useState } from 'react';
import { ModalProps, Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Referrer } from 'types/referrer';
import { BaseModal } from './BaseModal';
import { queryReferrers } from 'apis';

const useStyles = makeStyles(() =>
  createStyles({
    mainInfo: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '350px',
    },
    nameBlock: {
      width: '100%',
      cursor: 'pointer',
      padding: '8px',
      '&:hover': {
        backgroundColor: '#6FD7FD',
      },
    },
  })
);

type ReferrerModalListProps = {
  callback: (refInfo: Pick<Referrer, 'uid' | 'name'>) => void;
  onClose: () => void;
} & Omit<ModalProps, 'children'>;

export const ReferrerListModal: React.VFC<ReferrerModalListProps> = ({
  callback,
  onClose,
  ...modalProps
}) => {
  const classes = useStyles();
  const [referrerList, setReferrerList] = useState<Array<
    Pick<Referrer, 'uid' | 'name'>
  > | null>(null);

  useEffect(() => {
    const fetchList = async () => {
      const snapshot = await queryReferrers();
      const data = snapshot.docs.map((doc) => ({
        uid: doc.data().uid,
        name: doc.data().name,
      }));
      setReferrerList(data);
    };
    fetchList();
  }, []);

  return (
    <BaseModal title="選擇推薦人" onClose={onClose} {...modalProps}>
      {referrerList &&
        referrerList.map((item, idx) => (
          <Box
            key={idx}
            className={classes.mainInfo}
            onClick={() => callback(item)}
          >
            <Box className={classes.nameBlock}>
              <Typography>{item.name}</Typography>
            </Box>
          </Box>
        ))}
    </BaseModal>
  );
};
