import React, { useState, useEffect } from 'react';
import { getDocs, QueryDocumentSnapshot } from 'firebase/firestore';

import {
  Container,
  Grid,
  Typography,
  FormControl,
  Box,
  Select,
  MenuItem,
} from '@mui/material';

import { getApplicationsQuery } from 'apis';
import { useAuth } from 'app/ProviderAuth';
import { Application, ApplicationStatus } from 'types';
import { ApplicationSentDetail } from './components';

export const ApplicationsSentPage = () => {
  const { user } = useAuth();

  const [viewStatus, setViewStatus] = useState('all');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortedApplications, setSortedApplications] = useState<
    QueryDocumentSnapshot<Application>[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const status = viewStatus === 'all' ? undefined : viewStatus;
        const q = getApplicationsQuery({
          applicantUid: user.uid,
          status,
          sortField: 'createdAt',
        });

        const apps = await getDocs(q);
        const sortedAppsDocs = apps.docs.sort((a, b) => {
          const dateA = a.data().createdAt?.toDate().getTime();
          const dateB = b.data().createdAt?.toDate().getTime();
          if (!dateA || !dateB) return 0;
          if (sortOrder === 'asc') return dateA - dateB;
          return dateB - dateA;
        });

        setSortedApplications(sortedAppsDocs);
      }
    };

    fetchData();
  }, [user, viewStatus, sortOrder]);

  const renderApplication = (doc: QueryDocumentSnapshot<Application>) => (
    <Grid item xs={12} key={`${doc.id}`}>
      <ApplicationSentDetail application={doc.data()} />
    </Grid>
  );

  return (
    <Box sx={{ margin: '40px auto' }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={8} xs={12}>
            <Typography variant="h2">別人推我</Typography>
          </Grid>
          <Grid item lg={2} xs={6}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <Select
                value={viewStatus}
                onChange={(e) => setViewStatus(e.target.value as string)}
              >
                <MenuItem value="all">全部</MenuItem>
                <MenuItem value={ApplicationStatus.REVIEWING}>待處理</MenuItem>
                <MenuItem value={ApplicationStatus.REFERRED}>已內推</MenuItem>
                <MenuItem value={ApplicationStatus.REJECTED}>已被拒</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={2} xs={6}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <Select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value as 'asc' | 'desc')}
              >
                <MenuItem value="desc">時間 舊 - 新</MenuItem>
                <MenuItem value="asc">時間 新 - 舊</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {!sortedApplications.length ? (
          <Box sx={{ textAlign: 'center', my: 8 }}>
            <Typography>目前沒有內推申請</Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {sortedApplications.map(renderApplication)}
          </Grid>
        )}
      </Container>
    </Box>
  );
};
