import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { Button, NexTextField } from 'components';

const FeedbackDialog = ({ open, title, content, onConfirm, onClose }) => {
  const [feedback, setFeedback] = React.useState('');
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
        <NexTextField
          autoFocus
          margin="dense"
          id="feedback"
          label="給申請者的回饋"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm(feedback)}>是</Button>
        <Button onClick={onClose} color="secondary">
          否
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
