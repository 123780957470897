import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

import { BaseModal } from './BaseModal';
import { Button } from 'components';

const useStyles = makeStyles(() =>
  createStyles({
    baseModal: {
      padding: '16px 8px 24px 8px !important',
    },
    textArea: {
      width: '100%',
      maxWidth: '535px',
      minHeight: '160px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      padding: '16px 0',
    },
  })
);

type ConfirmModallProps = {
  title: string;
  name: string;
  open: boolean;
  onClose: () => void;
  onProceed: (confirm?: boolean) => void;
};

export const ConfirmModal = ({ onProceed, ...props }: ConfirmModallProps) => {
  const classes = useStyles();
  return (
    <BaseModal className={classes.baseModal} {...props}>
      <div>
        <h4>{props.name}</h4>
        <div className={classes.buttonContainer}>
          <Button color="secondary" onClick={() => onProceed(false)}>
            取消
          </Button>
          <Button onClick={() => onProceed(true)}>確認</Button>
        </div>
      </div>
    </BaseModal>
  );
};
