import React from 'react';

import { Container } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { BLACK, WHITE } from 'constants/colors';
import { useFooter } from 'hooks/useFooter';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      background: WHITE,
      boxShadow: '0 -4px 8px 0 rgb(0 0 0 / 20%)',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      padding: '32px 0',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    footerLogo: {
      maxWidth: '100%',
    },
    footerDescription: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gridRowGap: '16px',
      color: BLACK,
      fontSize: '16px',
      lineHeight: '125%',
    },
    contact: {
      display: 'flex',
      flex: 1,
      gridColumnGap: '16px',
    },
  });
});

export const Footer = () => {
  const classes = useStyles();
  const { isShowing } = useFooter();
  if (!isShowing) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <img
          src="/nex-work-logo-footer.png"
          alt="NEX Work"
          width="101"
          loading="lazy"
          className={classes.footerLogo}
        />
        <div className={classes.footerDescription}>
          <div>
            NEX Work 為非營利服務平台，由美國 NEX Foundation 建置，2020
            年正式營運。NEX Foundation 為美國註冊商標，屬 NEX Foundation 所有。
            其餘各商標均為個別原始公司所有，NEX Foundation
            與各該公司並無代理權限或合作關係。
          </div>
          <div>Copyright &copy; 2020</div>
          <div className={classes.contact}>
            <a
              href="https://www.instagram.com/nexfoundation/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/instagram-logo.svg"
                loading="lazy"
                alt="Instagram profile"
              />
            </a>
            <a
              href="https://www.facebook.com/NEXFoundation"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/facebook-logo.svg"
                loading="lazy"
                alt="Facebook profile"
              />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};
