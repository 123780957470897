import { useState, useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { SearchBar, MessageCard, ChatWindow } from './components';
import { createStyles, makeStyles } from '@mui/styles';
import { Conversation, MessageStatus } from 'types/conversation';
import { queryConversationsByUser } from '../../apis';
import { useAuth } from 'app/ProviderAuth';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      borderLeft: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      minHeight: '90vh',
    },
    leftPanel: {
      borderRight: '1px solid #ccc',
      padding: '16px',
    },
    rightPanel: {
      padding: '16px 16px 16px 8px',
      maxHeight: '90vh', // To-do: to be discussed
    },
  })
);

export const ConversationPage = () => {
  const classes = useStyles();
  const [conversations, setConversations] =
    useState<Array<Conversation> | null>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [receiver, setReceiver] = useState<string | null>();
  const [lastUnreadMessageId, setLastUnReadMessageId] = useState<string | null>(
    null
  );
  const [messageStatus, setMassageStatus] =
    useState<Array<MessageStatus> | null>();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const fetchConversation = async () => {
        await queryConversationsByUser(user.uid, setConversations);
      };
      fetchConversation();
    }
  }, [user]);

  const handleConversationRoom = (id: string) => {
    setRoomId(id);
    if (conversations) {
      const result = conversations.find((item) => item.roomId === id);
      if (result !== undefined) {
        setReceiver(getReceiver(result));
        setMassageStatus(result.messageStatus);
        setLastUnReadMessageId(getLastUnreadMessage(result.messageStatus));
      }
    }
  };

  const getReceiver = (conversation: Conversation) => {
    const index = conversation.participantsId.findIndex(
      (item) => item !== user?.uid
    );
    return conversation.participantsName[index];
  };

  const setChatWindowSettings = (room: string, name: string) => {
    setReceiver(name);
    setRoomId(room);
  };

  const checkIsRead = (conversation: Conversation) => {
    if (conversation.messageStatus && conversation.roomId !== roomId) {
      return conversation.messageStatus.find(
        (item) => item.participantId === user?.uid
      )?.messageRead.isRead as boolean;
    }
    return true;
  };

  const getLastUnreadMessage = (messageStatus: Array<MessageStatus>) => {
    if (user && messageStatus) {
      const result = messageStatus.find(
        (item) => item.participantId === user.uid
      );
      if (result) {
        return result.messageRead.isRead
          ? null
          : result.messageRead.lastUnreadMessageId;
      }
    }
    return null;
  };

  return (
    <Container maxWidth="xl">
      <Grid container className={classes.container}>
        <Grid item xs={4} className={classes.leftPanel}>
          {conversations && (
            <>
              <SearchBar
                infos={conversations.map((item) => ({
                  roomId: item.roomId as string,
                  name: getReceiver(item),
                }))}
                callback={setChatWindowSettings}
              />
              <Box>
                {conversations.map((item) => (
                  <Box
                    key={item.roomId}
                    onClick={() =>
                      handleConversationRoom(item.roomId as string)
                    }
                  >
                    <MessageCard
                      avatar=""
                      name={getReceiver(item)}
                      lastMessage={item.lastMessage}
                      isRead={checkIsRead(item)}
                      active={item.roomId === roomId}
                    />
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={8} className={classes.rightPanel}>
          {user && (
            <ChatWindow
              userInfo={{ uid: user.uid, name: user.displayName as string }}
              roomId={roomId as string}
              receiver={receiver as string}
              messageStatus={messageStatus as Array<MessageStatus>}
              unReadMsgId={lastUnreadMessageId}
              callback={setChatWindowSettings}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
