import { SyntheticEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export const SearchBar: React.FC<{
  infos: Array<{ roomId: string; name: string }>;
  callback: (room: string, name: string) => void;
}> = ({ infos, callback }) => {
  const [value, setValue] = useState<string | null>('');

  const handleInputChange = (event: SyntheticEvent, value: string) => {
    setValue(value);
  };

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    handleCallBack(value as string);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCallBack(value as string);
    }
  };

  const handleCallBack = (val: string) => {
    const res = infos.find((item) => item.name === val);
    if (res) {
      setValue('');
      callback(res.roomId, res.name);
    }
  };

  return (
    <Autocomplete
      id="search"
      freeSolo
      options={infos.map((item) => item.name)}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search..."
          // InputProps={{
          //   startAdornment: <SearchIcon sx={{ mr: 1 }}></SearchIcon>,
          // }}
        />
      )}
    />
  );
};
