import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Application } from 'types';
import { BaseInfoCard } from 'components/InfoCard';
import { ApplicationModal } from 'components/Modal';
import { EmailIcon, LinkedInIcon } from 'components/icons';

import avatarImg from 'resource/avatar.png';

type ApplicationInfoCardProps = {
  application: Pick<
    Application,
    'firstName' | 'lastName' | 'applicantEmail' | 'linkedIn' | 'about'
  > &
    Partial<Application>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    heading: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    selfIntro: {
      display: '-webkit-box',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    applicantNote: {
      display: '-webkit-box',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    link: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

export const ApplicationInfoCard: React.FC<ApplicationInfoCardProps> = ({
  application,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  function handleModalOpen() {
    setIsModalOpen(true);
  }
  const { firstName, lastName, applicantEmail, linkedIn, about } = application;
  const displayName = `${firstName} ${lastName}`;

  return (
    <BaseInfoCard className={classes.root}>
      <Box display="flex" mb={3}>
        <Box mr={2.5}>
          <img src={avatarImg} width="40" alt="Avatar icon" />
        </Box>
        <Typography variant="h2" color="textPrimary">
          {displayName}
        </Typography>
      </Box>
      <Box>
        {applicantEmail && (
          <Link href={`mailto:${applicantEmail}`} target="_blank">
            <EmailIcon />
          </Link>
        )}
        {linkedIn && (
          <Link href={linkedIn} target="_blank">
            <LinkedInIcon />
          </Link>
        )}
      </Box>

      {about && (
        <Box>
          <Typography variant="h3" className={classes.heading}>
            給推薦人的話
          </Typography>
          <Typography className={classes.applicantNote}>{about}</Typography>
          <Link className={classes.link} onClick={handleModalOpen}>
            查看細節
          </Link>
        </Box>
      )}
      <ApplicationModal
        open={isModalOpen}
        application={application}
        onClose={() => setIsModalOpen(false)}
      />
    </BaseInfoCard>
  );
};
