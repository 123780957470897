import { FieldValue, Timestamp } from 'firebase/firestore';

export enum ApplicationStatus {
  REVIEWING = 'reviewing',
  REFERRED = 'referred',
  REJECTED = 'rejected',
}

export type Application = {
  companyId: string;
  company: string;
  referrerUid: string;
  referrerName: string;
  about: string;
  applicantEmail: string;
  applicantUid: string;
  firstName: string;
  lastName: string;
  introduction: string;
  jobId0: string;
  jobLink0: string;
  jobTitle0: string;
  linkedIn: string;
  needSponsorship: string; // yes, no
  resumeFirebasePath: string;
  yearsOfExperience: number;
  status: ApplicationStatus;
  createdAt: Timestamp;
  statusChangeTimestamp: Timestamp;

  highestDegree?: string;
  isResumeUploaded?: boolean;
  referrerFeedback?: string;
  portfolioUrl?: string;
  coverLetterFirebasePath?: string;
  phoneNumber?: string;
  address?: string;

  //duplicate fields
  resumeDownloadUrl?: string;
};

export type ApplicationToWrite = Omit<
  Application,
  'createdAt' | 'statusChangeTimestamp'
> & {
  createdAt: FieldValue;
  statusChangeTimestamp: FieldValue | null;
};

export type ApplicationWithId = Application & {
  id: string;
};
