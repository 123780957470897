import React from 'react';

import { Typography, Grid, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { getDaysAgo, displayDaysAgo } from 'helper/common';
import { Application, ApplicationStatus } from 'types/application';
import { getDisplayName } from 'helper/application';
import { Button } from 'components';

const useStyles = makeStyles(() => {
  return createStyles({
    nameTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '39px',
    },
    lightText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'right',
      color: '#000000',
      opacity: '0.5',
    },
    about: {
      whiteSpace: 'pre-wrap',
    },
  });
});

interface ApplicationDetailCardProp {
  id: string;
  application: Application;
  onDetailButtonClick: (applicationId: string) => void;
  onRejectButtonClick: (applicationId: string) => void;
}

export const ApplicationDetailCard = ({
  id,
  application,
  onDetailButtonClick,
  onRejectButtonClick,
}: ApplicationDetailCardProp) => {
  const {
    createdAt,
    about,
    linkedIn,
    resumeDownloadUrl,
    resumeFirebasePath,
    status,
    jobTitle0,
  } = application;
  const classes = useStyles();
  const displayName = getDisplayName(application);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={10} xs={12} className={classes.nameTitle}>
          {displayName}
        </Grid>
        <Grid item lg={2} className={classes.lightText}>
          <Box display={{ xs: 'none', lg: 'flex' }} justifyContent="flex-end">
            申請時間 : {displayDaysAgo(getDaysAgo(createdAt))}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={2} xs={12}>
          <Grid>
            <Typography variant="subtitle2" noWrap={true}>
              職缺
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1" noWrap>
              {jobTitle0}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Grid>
            <Typography variant="subtitle2" noWrap={true}>
              連結
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body1">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={linkedIn}
                style={{ marginRight: '10px', color: 'black' }}
              >
                LinkedIn
              </a>
              <a
                style={{ color: 'black' }}
                target="_blank"
                rel="noopener noreferrer"
                /**
                 * to support some old applications which have resumeDownloadUrl
                 * and their resumeFirebasePath are in forms of resume/<hash>/<filename>
                 */
                href={resumeDownloadUrl || resumeFirebasePath}
              >
                履歷
              </a>
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Grid>
            <Typography variant="subtitle2">自我介紹</Typography>
          </Grid>
          <Grid className={classes.about}>
            <Typography variant="body1">{about}</Typography>
          </Grid>
        </Grid>
        <Grid item lg={2} xs={12}>
          {status === ApplicationStatus.REVIEWING && (
            <Button
              color="secondary"
              fullWidth
              onClick={() => onRejectButtonClick(id)}
            >
              跳過
            </Button>
          )}
        </Grid>

        <Grid item lg={2} xs={12}>
          <Button fullWidth onClick={() => onDetailButtonClick(id)}>
            詳細資料
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.lightText}>
          <Box textAlign="center" display={{ lg: 'none' }}>
            申請時間 : {displayDaysAgo(getDaysAgo(createdAt))}
          </Box>
        </Grid>
      </Grid>
      <hr />
    </div>
  );
};
