import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { Application, Referrer } from 'types';

export type AxisData = {
    x: string[];
    y: number[];
};
export type Data = Application | Referrer;
export const getNumByScale = (data: Timestamp[], startTime: Dayjs, endTime: Dayjs, scale: ManipulateType): AxisData => {
    const timeMap:{ [key: string]: number }  = {};
    const diff = endTime.startOf(scale).diff(startTime.startOf(scale), scale) + 1;
    for (let i = 0; i < diff; i++) {
        timeMap[startTime.startOf(scale).add(i, scale).format('YYYY-MM-DD')] =  0;
    }
    data.forEach((item) => {
        const time = dayjs(item.toDate()).startOf(scale).format('YYYY-MM-DD');
        timeMap[time] =  (timeMap[time] || 0) + 1;
    })
    return {
        x: Object.keys(timeMap),
        y: Object.values(timeMap),
    } as AxisData;
};

export const validScale = (startTime: Dayjs, endTime: Dayjs, scale: ManipulateType): boolean => {
    return endTime.startOf(scale).diff(startTime.startOf(scale), scale) > 0;
}