// React
import * as React from 'react';
import { Box } from '@mui/material/index';

import { getInitializedName } from 'helper/common';

export const ReferNudgeAction = ({ referrer, applicant, company }) => {
  return (
    <Box fontSize={14}>
      {referrer + ' 將 ' + getInitializedName(applicant) + ' 的內推申請提交給 '}
      <Box component="span" fontWeight="fontWeightBold" fontSize={14}>
        {company}
      </Box>
    </Box>
  );
};
