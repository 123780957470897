import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { queryReferrers } from 'apis';

import { useAuth } from 'app/ProviderAuth';

import { Button } from 'components';
import { Referrer } from 'types';

type ReferrerRow = Referrer & { id: string };
export const AdminReferrersPage = () => {
  const { isLoading: userIsLoading, isAdmin } = useAuth();
  const [referrers, setReferrers] = useState<ReferrerRow[]>([]);

  useEffect(() => {
    if (!userIsLoading && isAdmin) {
      const fetchData = async () => {
        const snapshot = await queryReferrers(
          'createdAt', 
          'desc'
        );
        const _referrers:ReferrerRow[] = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
          };
        });
        setReferrers(_referrers);
      }
      fetchData();
    };
  }, [userIsLoading, isAdmin]);

  const getCSVFromTable = () => {
    const csv = [
      // Header row
      'Name, Company, CreatedAt',
    ];

    function* generateFields(referrer: ReferrerRow) {
      yield referrer.name;
      yield referrer.company;
      yield (referrer.createdAt || referrer.updatedAt)
        ?.toDate()
        .toLocaleString();
    }

    for (const referrer of referrers) {
      const row = [];
      const referrerFields = generateFields(referrer);

      let field = referrerFields.next();
      while (!field.done) {
        row.push(field.value?.replaceAll(/[，,\n]/g, ' '));        
        field = referrerFields.next();
      }

      csv.push(row.join(','));
    }

    return csv.join('\n');
  };

  const downloadCSV = () => {
    const csv = getCSVFromTable();
    const csvFile = new Blob(["\ufeff" +csv], { type: 'text/csv' });

    const downloadLink = document.createElement('a');
    downloadLink.download = 'referrers.csv';
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const renderReferrer = (referrer: ReferrerRow) => {
    return (
      <TableRow key={referrer.id}>
        <TableCell>{referrer.name}</TableCell>
        <TableCell>{referrer.company}</TableCell>
        <TableCell>
          {(referrer.createdAt || referrer.updatedAt)
            ?.toDate()
            .toLocaleString()}
        </TableCell>
      </TableRow>
    );
  };

  const ToolBar = () => {
    return (
      <Box py={2} display="flex" width="100%" justifyContent="flex-end">
        <Button onClick={downloadCSV}>下載 CSV</Button>
      </Box>
    );
  };

  const ReferrerTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>CreatedAt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{referrers.map(renderReferrer)}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (userIsLoading) {
    return null;
  }

  if (!isAdmin) {
    return <Navigate to="/companies" replace />;
  }

  return (
    <Box mx="8%">
      <ToolBar />
      <ReferrerTable />
    </Box>
  );
};
