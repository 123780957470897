import { conversationsCollection } from 'configs/db';
import {
  doc,
  collection,
  addDoc,
  updateDoc,
  serverTimestamp,
  DocumentSnapshot,
  QuerySnapshot,
  query,
  getDocs,
  getDoc,
  where,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import { Conversation, NameAndId, Message } from 'types';
import { MessageStatus } from 'types/conversation';
import { getTimeFromFireStore } from 'helper/conversation';
import { Timestamp } from '@firebase/firestore-types';

/* Conversation apis */
export const queryConversationsByUser = async (
  uid: string,
  setConversations: React.Dispatch<React.SetStateAction<Conversation[] | null>>
) => {
  // Real-time Listening
  const q = query(
    conversationsCollection,
    where('participantsId', 'array-contains', uid)
  );
  onSnapshot(q, (querySnapshot) => {
    const data: Conversation[] = [];
    querySnapshot.forEach((doc) => {
      const obj = {
        roomId: doc.id,
        participantsId: doc.data().participantsId,
        participantsName: doc.data().participantsName,
        messageStatus: doc.data().messageStatus,
        lastMessage: doc.data().lastMessage,
      };
      data.push(obj);
    });
    setConversations(data);
  });
};

// filter conversation by user id
export const queryConversationsById = async (
  id: string
): Promise<QuerySnapshot<Conversation>> => {
  return await getDocs(
    query(
      conversationsCollection,
      where('participantsId', 'array-contains', id)
    )
  );
};

export const createConversation = async (
  participants: Array<NameAndId>
): Promise<DocumentSnapshot<Conversation>> => {
  const messageStatus = participants.map((item) => ({
    participantId: item.uid,
    messageRead: {
      lastUnreadMessageId: '',
      isRead: true,
    },
  }));
  const res = await addDoc(conversationsCollection, {
    participantsId: participants.map((item) => item.uid),
    participantsName: participants.map((item) => item.name),
    messageStatus: messageStatus,
    created: serverTimestamp(),
    lastMessage: { content: '', timestamp: serverTimestamp() },
  });
  return await getDoc(res);
};

// Update Status
export const updateMessageStatus = async (
  roomId: string,
  status: Array<MessageStatus>
) => {
  const docRef = doc(conversationsCollection, roomId);
  await updateDoc(docRef, {
    messageStatus: status,
  });
};

/* Message apis */
export const addMessage = async (roomId: string, message: Message) => {
  const { sender, content } = message;
  const docRef = doc(conversationsCollection, roomId);
  const colRef = collection(docRef, 'messages');
  const docSnapshot = await getDoc(docRef);
  const status = docSnapshot.data()?.messageStatus;

  const msg = await addDoc(colRef, {
    sender: sender,
    content: content,
    timestamp: serverTimestamp(),
  });

  // Update last message in Conversation Collection
  await updateDoc(docRef, {
    lastMessage: {
      content: content,
      timestamp: serverTimestamp(),
    },
  });

  // Update message status
  status?.forEach((item) => {
    if (item.participantId !== sender) {
      item.messageRead.isRead = false;
      item.messageRead.lastUnreadMessageId = msg.id;
    }
  });
  await updateDoc(docRef, {
    messageStatus: status,
  });
};

/* Conversation apis */
export const queryMessagesByRoom = async (
  roomId: string,
  setMessagesByDate: React.Dispatch<
    React.SetStateAction<Array<{ date: string; messages: Message[] }>>
  >
) => {
  const docRef = doc(conversationsCollection, roomId);
  const colRef = collection(docRef, 'messages');
  const q = query(colRef, orderBy('timestamp', 'desc'));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const groupedMessages: { [date: string]: Message[] } = {};

    querySnapshot.forEach((doc) => {
      const obj = {
        content: doc.data().content,
        sender: doc.data().sender,
        timestamp: doc.data().timestamp,
        messageId: doc.id,
      };

      // Iterate through the messages array and group them by date
      const time = getTimeFromFireStore(obj.timestamp as Timestamp);

      if (!groupedMessages[time]) {
        groupedMessages[time] = [];
      }
      groupedMessages[time].unshift(obj);
    });
    // Convert the grouped messages object into an array of date/message pairs
    const groupedMessagesArray = Object.keys(groupedMessages).map((date) => ({
      date,
      messages: groupedMessages[date],
    }));
    setMessagesByDate(groupedMessagesArray);
  });

  // Return a cleanup function
  return () => {
    unsubscribe();
  };
};
/* Message apis */
