import { useState, useEffect } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Timestamp } from '@firebase/firestore-types';
import { getTimeFromFireStore } from 'helper/conversation';

type MessageCardProps = {
  avatar: string;
  name: string;
  lastMessage: {
    content: string;
    timestamp: Timestamp;
  };
  isRead: boolean;
  active: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      marginTop: '8px',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgb(56,216,164, 0.5)',
      },
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    rightBox: {
      marginLeft: '16px',
      width: '100%',
    },
    active: {
      backgroundColor: 'rgb(56,216,164, 0.2)',
    },
    text: {
      color: '#222',
    },
    unread: {
      fontWeight: 700,
    },
    title: {
      fontWeight: 700,
    },
  })
);

export const MessageCard = ({
  avatar,
  name,
  lastMessage,
  isRead,
  active,
}: MessageCardProps) => {
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  const [displayMessage, setDisplayMessage] = useState<string | null>(null);

  const classes = useStyles();
  useEffect(() => {
    if (lastMessage) {
      const { timestamp, content } = lastMessage;
      if (timestamp) {
        setDisplayTime(getTimeFromFireStore(timestamp));
      }
      setDisplayMessage(
        `${content.substring(0, 20)}${content.length > 20 ? '...' : ''}`
      );
    }
  }, [lastMessage]);

  const stringAvatar = () => {
    const names = name.split(' ');
    return {
      children: names.map((n) => n[0]),
    };
  };

  return (
    <Box className={`${classes.card} ${active ? classes.active : ''}`}>
      <Avatar {...stringAvatar()} />
      <Box className={classes.rightBox}>
        <Box className={classes.flexBox}>
          <Typography className={classes.title}>{name}</Typography>
          <Typography variant="body2" className={classes.text}>
            {displayTime}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          className={`${classes.text} ${isRead ? '' : classes.unread}`}
        >
          {displayMessage}
        </Typography>
      </Box>
    </Box>
  );
};
