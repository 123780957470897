import React from 'react';
import { useState } from 'react';
import {
  Typography,
  Box,
  Divider,
  CardContent,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from 'components';
import { WHITE } from 'constants/colors';
import { getDaysAgo, displayDaysAgo } from 'helper/common';
import { Timestamp } from 'firebase/firestore';
const useStyles = makeStyles((theme) => ({
  Card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: WHITE,
    borderRadius: '12px',
    boxShadow: '0 4px 4px 0 rgb(0 0 0 / 25%)',
    maxWidth: '1152px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  informationContainer: {
    display: 'flex',
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: '#F4F6FA',
    padding: '20px 36px 16px 36px',
    '& > div': {
      textAlign: 'center',
      flex: 1,
      padding: '0 16px',
    },
  },
  customBackground: {
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: '#F4F6FA',
    padding: '20px 36px 16px 36px',
  },
  Box: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
  },
  BoldText: {
    fontWeight: 500,
    fontSize: '18px',
    fontFamily: 'Noto Sans TC',
    textAlign: 'center',
    fontStyle: 'normal',
    lineHeight: '125%',
  },
  SecondText: {
    fontWeight: 400,
    color: '#4D5461',
    fontStyle: 'normal',
    lineHeight: '1.4',
  },
  NoteText: {
    fontfamily: 'Noto Sans TC',
    fontSize: '16px',
    color: '#141921',
    fontStyle: 'normal',
    fontweight: 400,
    lineHeight: '140%',
  },
  divider: {
    width: '1px',
    backgroundColor: '#C6CBD3',
  },
}));

interface Referrer {
  preferredName?: string;
  name: string;
  department?: string;
  jobTitle: string;
  location?: string;
  applicantNote?: string;
  referredCount?: number;
  applicationCount?: number;
  lastReferredDate?: Timestamp;
  role?: string;
  internship?: string;
  careerPage?: string;
}

interface ReferrerDetailsProps {
  referrer: Referrer;
  onButtonClick: () => void;
}

export const ReferrerDetailsWhole = ({
  referrer,
  onButtonClick,
}: ReferrerDetailsProps) => {
  const classes = useStyles();
  const maxLength = 100;

  const [showFullContent, setShowFullContent] = useState(
    referrer.applicantNote && referrer.applicantNote.length > maxLength
  );

  const handleToggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const openExternalUrl = () => {
    window.open(careerPage, '_blank');
  };

  const {
    department = 'NA',
    location = '不限地點',
    role = '不限部門職位',
    internship = '可',
    careerPage,
    applicantNote = '',
    referredCount = 0,
    lastReferredDate,
  } = referrer;

  return (
    <CardContent className={classes.Card}>
      <Box className={classes.informationContainer}>
        <Box>
          <Typography className={classes.SecondText} fontSize="16px">
            職稱
          </Typography>
          <Typography className={classes.BoldText}>
            {referrer.jobTitle}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography className={classes.SecondText} fontSize="16px">
            所屬部門
          </Typography>
          <Typography className={classes.BoldText}>{department}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography className={classes.SecondText} fontSize="16px">
            可內推地點
          </Typography>
          <Typography className={classes.BoldText}>{location}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography className={classes.SecondText} fontSize="16px">
            可內推職位
          </Typography>
          <Typography className={classes.BoldText}>{role}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <Typography className={classes.SecondText} fontSize="16px">
            可內推實習
          </Typography>
          <Typography className={classes.BoldText}>{internship}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: 2.5,
          paddingBottom: 2,
          paddingLeft: '36px',
          paddingRight: '27px',
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box gap={2} display="flex" justifyContent="space-between">
          <Box gap={1} display="flex" alignItems="flex-end">
            <Typography className={classes.BoldText}>
              {referrer.preferredName || referrer.name}
            </Typography>
            {careerPage && (
              <IconButton
                color="primary"
                sx={{ padding: 0 }}
                onClick={openExternalUrl}
              >
                <img
                  src="/mingcute_external-link-fill.svg"
                  alt="External Link"
                />
              </IconButton>
            )}
          </Box>
          <Box gap={1} display="flex" alignItems="flex-start">
            <Typography
              className={classes.SecondText}
              fontSize="14px"
            >{`已內推 ${referredCount || 0} 人`}</Typography>
            <Typography
              className={classes.SecondText}
              fontSize="14px"
            >{`最近內推時間：${displayDaysAgo(
              getDaysAgo(lastReferredDate)
            )}`}</Typography>
          </Box>
        </Box>
        <Box
          gap={2}
          display="flex"
          flexDirection="column"
          sx={{ overflow: 'hidden' }}
        >
          <Typography
            className={classes.NoteText}
            noWrap={showFullContent ? true : false}
          >
            {applicantNote}
          </Typography>
          {applicantNote.length > maxLength && (
            <IconButton color="primary" onClick={handleToggleContent}>
              <img
                src={
                  showFullContent
                    ? '/ReadMoreButton.svg'
                    : '/HideMoreButton.svg'
                }
                width="24"
                height="17"
                alt="External Link"
              />
            </IconButton>
          )}
          <Button shape="round" onClick={onButtonClick}>
            {' '}
            幫我內推
          </Button>
        </Box>
      </Box>
    </CardContent>
  );
};