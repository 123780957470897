import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Message } from 'types/conversation';
import { useAuth } from 'app/ProviderAuth';
import { MessageStatus } from 'types/conversation';
import { Timestamp } from '@firebase/firestore-types';
import { updateMessageStatus, queryMessagesByRoom } from 'apis';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '90%',
    },
    reverse: {
      // For dialogue displays from the bottom
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    room: {
      overflowY: 'auto',
    },
    dialogueBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      margin: '0 16px 16px 16px',
      '& > div': {
        border: '1px solid rgba(56,216,164, 0.5)',
      },
    },
    selfBlock: {
      alignItems: 'end',
      '& > div': {
        backgroundColor: 'rgba(106,220,233, 0.5)',
        border: 'none',
      },
    },
    messageBox: {
      padding: '8px 12px',
      borderRadius: '12px',
      minWidth: '40%',
      maxWidth: '80%',
    },
    text: {
      wordBreak: 'break-all',
    },
    unread: {
      color: '#cccccc',
      alignSelf: 'center',
      marginBottom: '8px',
    },
    timestamp: {
      fontSize: '12px',
      textAlign: 'end',
      marginTop: '8px',
    },
    center: {
      textAlign: 'center',
    },
  })
);

const getLocalTime = (timestamp: Timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp.seconds * 1000);
  const timeString = date.toLocaleString();
  return `${timeString.substring(
    timeString.indexOf(',') + 2,
    timeString.lastIndexOf(':')
  )} ${timeString.slice(-2)}`;
};

export const Messages: React.FC<{
  uid: string;
  roomId: string;
  messageStatus: Array<MessageStatus> | null;
  unReadMsgId: string | null;
}> = ({ uid, roomId, messageStatus, unReadMsgId }) => {
  const classes = useStyles();
  const [messagesByDate, setMessagesByDate] = useState<
    Array<{ date: string; messages: Message[] }>
  >([]);
  const { user } = useAuth();

  useEffect(() => {
    let cleanup: (() => void) | undefined;
    if (roomId) {
      const fetchMessages = async () => {
        cleanup = await queryMessagesByRoom(roomId, setMessagesByDate);
      };
      fetchMessages();
    }
    return () => cleanup && cleanup();
  }, [roomId]);

  useEffect(() => {
    const interval = setTimeout(() => {
      updateStatus();
    }, 0);
    return () => clearInterval(interval);
  }, [messagesByDate]);

  // Mark the message as read if the user is in the room
  const updateStatus = async () => {
    if (roomId && user && messageStatus) {
      messageStatus.forEach((item) => {
        if (user.uid === item.participantId && !item.messageRead.isRead) {
          item.messageRead.isRead = true;
          item.messageRead.lastUnreadMessageId = '';
        }
      });
      updateMessageStatus(roomId, messageStatus);
    }
  };

  return (
    <Box className={`${classes.container} ${classes.reverse} ${classes.room}`}>
      {messagesByDate &&
        messagesByDate.map((item, index) => (
          <Box key={index}>
            <Typography variant="body2" className={classes.center}>
              {item.date}
            </Typography>
            {item.messages.map((message, idx) => (
              <Box
                className={`${classes.dialogueBlock} ${
                  message.sender === uid ? classes.selfBlock : ''
                }`}
                key={idx}
              >
                {unReadMsgId && unReadMsgId === message.messageId && (
                  <Typography className={classes.unread} variant="body2">
                    - Unread Message -
                  </Typography>
                )}

                <Box className={classes.messageBox}>
                  <Typography variant="body2" className={classes.text}>
                    {message.content}
                  </Typography>
                  <Typography className={classes.timestamp}>
                    {getLocalTime(message.timestamp as Timestamp)}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
    </Box>
  );
};
