import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as EmailSvg } from 'resource/email.svg';
import { ReactComponent as LinkedInSvg } from 'resource/linkedin.svg';

export const EmailIcon: React.FC = (props) => (
  <SvgIcon
    component={EmailSvg}
    style={{ fontSize: 40 }}
    viewBox="-8 -10 40 40"
    {...props}
  />
);

export const LinkedInIcon: React.FC = (props) => (
  <SvgIcon
    component={LinkedInSvg}
    style={{ fontSize: 40 }}
    viewBox="-8 -8 40 40"
    {...props}
  />
);
