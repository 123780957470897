import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { createStyles, makeStyles } from '@mui/styles';
import { createConversation, addMessage } from 'apis';
import { NameAndId } from 'types';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      marginTop: '16px',
      width: '100%',
    },
    icon: {
      color: '#000',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      cursor: 'pointer',
    },
  })
);

function checkString(inputString: string) {
  // Check if the string only contains '\n' or is empty
  return /^(\n*|\s*)$/.test(inputString);
}

export const ConversationInputBar: React.FC<{
  roomId: string;
  participants: Array<NameAndId> | null;
  sender: string;
}> = React.memo(({ roomId, participants, sender }) => {
  const classes = useStyles();
  const [content, setContent] = useState<string>('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Detects Enter button on keyboard
    if (e.key === 'Enter' && e.shiftKey) {
      // add new line
      setContent(content + '\n');
    } else if (e.key === 'Enter') {
      // Create an new room
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (!roomId && participants) {
      const res = await createConversation(participants);
      sendMessage(res.id);
    } else {
      sendMessage(roomId);
    }
  };

  const sendMessage = async (id: string) => {
    if (!checkString(content)) {
      const data = {
        sender: sender,
        content: content,
      };
      addMessage(id, data);
      setContent('');
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  return (
    <TextField
      id="outlined-textarea"
      className={classes.input}
      value={content}
      multiline
      minRows={1}
      maxRows={2}
      onKeyDown={handleKeyDown}
      onChange={handleOnChange}
      InputProps={{
        style: {
          padding: '8px 16px',
        },
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        },
        endAdornment: (
          <IconButton className={classes.icon} onClick={() => handleSubmit()}>
            <SendIcon />
          </IconButton>
        ),
      }}
    />
  );
});

export default React.memo(ConversationInputBar);
