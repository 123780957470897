import React from 'react';

import { Button, styled } from '@mui/material';
import { GREY1, GREY7, GREY8, WHITE } from 'constants/colors';

interface NavbarButtonProps {
  children: React.ReactNode;
  href: string;
}

const StyledButton = styled(Button)(() => ({
  padding: '10px 16px',
  backgroundColor: WHITE,
  color: GREY1,
  fontSize: 16,
  lineHeight: '20px',

  '&:hover': {
    backgroundColor: GREY8,
  },

  '&:active': {
    backgroundColor: GREY7,
    fontWeight: 500,
  },
}));

export const NavbarButton = ({ href, children }: NavbarButtonProps) => (
  <StyledButton href={href}>{children}</StyledButton>
);
