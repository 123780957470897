import * as React from 'react';
import { Grid } from '@mui/material/index';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export const NexLoadingIcon = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      spacing={0}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Grid>
  );
};
