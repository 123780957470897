import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    dialogueBox: {
      padding: '16px',
      borderBottom: '1px solid #ccc',
    },
    title: {
      fontWeight: 700,
    },
  })
);

export const DialogTitle: React.FC<{ receiver: string }> = ({ receiver }) => {
  const classes = useStyles();
  return (
    <Box className={classes.dialogueBox}>
      <Typography className={classes.title}>{receiver}</Typography>
    </Box>
  );
};
