import { useMachine } from '@xstate/react';
import { createMachine, assign } from 'xstate';

import { ApplicationStatus } from 'types';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEMAOqA2BLAxsgLlgPYB2ASmAG5ZgDuAdAJIAiAMgKIDEAggAq9kA8gDV2AbQAMAXUShURWFkKlZIAB6IAjABYAzPQBsAdgCcBgBxGArABoQAT0QAmJ+fpWj5zU6sBfX3ZomLgExORUNAwsHJxk7ABS7ADCACqSMkgg8orKJKoaCDr6xmaWtg5auk7+gejYeLkU1HT0ccKM7ADqjAByAOKcSdw9Seys6arZSmH5Wlb6VuZWJrrWdo4IXu41IEH1oaRNka3s7V29A0mCPQBijGQAshOZU7mzCNo69EaaEitrFUKHncuhMEgMPn8ARAJCIEDgqj2IUaETokwU0xUmQKAFoDOtEHidkiGmEji1oux0TkZtjENonASEGD6Ks-NCSQdws0GG0Ot1+tTMXk6QgnEYDPQTE5NGUmZpFvQJKDwZCOXVkWTUbz2ABlACqrBSQreoqs2iM9E0PzlgIVks8YIh7P8QA */
  createMachine(
    {
      tsTypes: {} as import('./ApplicationDetailPage.machine.typegen').Typegen0,
      id: 'applicationReview',
      schema: {
        context: {} as { applicationStatus: ApplicationStatus },
        events: {} as
          | { type: 'APPROVE' }
          | { type: 'REJECT' }
          | { type: 'CANCEL' }
          | { type: 'CONFIRM' },
      },
      initial: 'IDLE',
      states: {
        IDLE: {
          on: {
            APPROVE: { target: 'REVIEWING', actions: ['approve'] },
            REJECT: { target: 'REVIEWING', actions: ['reject'] },
          },
        },
        REVIEWING: {
          on: {
            CANCEL: 'IDLE',
            CONFIRM: 'RESULT',
          },
        },
        RESULT: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        approve: assign({
          applicationStatus: ApplicationStatus.REFERRED,
        }),
        reject: assign({
          applicationStatus: ApplicationStatus.REJECTED,
        }),
      },
    }
  );

const useApplicationReviewMachine = function () {
  return useMachine(machine);
};

export default useApplicationReviewMachine;
