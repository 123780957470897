import React from 'react';
import { Box, Typography } from '@mui/material';

import {
  Button,
  ControlledField,
  ControlledUploadFile,
  NexForm,
  NexOption,
} from 'components';

import { ApplyFormProps } from '../ApplyPage.types';

export const ApplyForm: React.FC<ApplyFormProps> = ({
  control,
  errors,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <NexForm gap={4} handleSubmit={handleSubmit}>
      <Box display="grid" gap={2.5}>
        <Typography variant="h3">職缺資料</Typography>
        <ControlledField
          label="申請的職缺名稱 - Position Name"
          name="jobTitle0"
          required
          control={control}
          error={errors.jobTitle0}
        />

        <ControlledField
          label="申請的職缺連結 - Job Description Link"
          name="jobLink0"
          required
          control={control}
          error={errors.jobLink0}
        />

        <ControlledField
          helperText="部分公司職缺編號（Job ID）會顯示在職缺頁面"
          label="申請的職缺編號 - Job ID"
          name="jobId0"
          control={control}
        />

        <ControlledUploadFile
          label="上傳履歷 - Resume"
          helperText="可接受檔案格式：PDF"
          name="resume"
          required
          control={control}
          error={errors.resume}
          accept=".pdf,.doc,.docx"
        />

        <ControlledUploadFile
          label="上傳 - Cover Letter"
          helperText="可接受檔案格式：PDF"
          name="coverLetter"
          control={control}
          accept=".pdf,.doc,.docx"
        />

        <ControlledField
          label="作品集連結 - Portfolio Link"
          name="portfolioUrl"
          control={control}
        />
      </Box>
      <Box display="grid" gap={2.5}>
        <Typography variant="h3">聯絡資訊</Typography>
        <ControlledField
          label="英文姓氏 - Last Name"
          name="lastName"
          required
          control={control}
          error={errors.lastName}
        />

        <ControlledField
          label="英文名字 - First Name"
          name="firstName"
          required
          control={control}
          error={errors.firstName}
        />

        <ControlledField
          label="電子郵件 - Email"
          name="email"
          type="email"
          required
          control={control}
          error={errors.email}
        />

        <ControlledField
          label="聯絡電話 - Phone"
          name="phoneNumber"
          required
          control={control}
          error={errors.phoneNumber}
        />

        <ControlledField
          label="居住地址 - Address"
          name="address"
          control={control}
        />

        <ControlledField
          helperText="推薦人若需要更多你的資訊以協助內推，可能會於 Linkedin 直接與你聯絡。"
          label="Linkedin - 連結"
          name="linkedIn"
          required
          control={control}
          error={errors.linkedIn}
        />

        <NexOption
          label="需要公司幫忙申請簽證嗎 - Visa Sponsorship?"
          name="needSponsorship"
          control={control}
          options={[
            { label: '是', value: 'yes' },
            { label: '否', value: 'no' },
          ]}
        />
        <ControlledField
          helperText="
            簡短向推薦人介紹你是誰，為什麼會想申請這個職缺，並以第三人稱的方式，
            列舉幾個原因告訴推薦人為什麼你適合這個職缺。
            "
          label="推薦重點"
          name="introduction"
          required
          control={control}
          error={errors.introduction}
          multiline
          rows={4}
        />

        <Button
          disabled={isSubmitting}
          type="submit"
          color="primary"
          sx={{ width: 'fit-content' }}
        >
          送出
        </Button>
      </Box>
    </NexForm>
  );
};
