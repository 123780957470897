import React, { useState, SyntheticEvent } from 'react';
import { EditReferrerProfilePage } from 'views/Profiles/Referrers/EditReferrerProfilePage';
import { EditApplicantProfilePage } from 'views/Profiles/Applicant/EditApplicantProfilePage';
import { useAuth } from 'app/ProviderAuth';
import { Box, Container, Tabs, Tab } from '@mui/material';

const APPLICANT = 'applicant';
const REFERRER = 'referrer';
type ProfileType = typeof APPLICANT | typeof REFERRER;

export const EditProfilePage = () => {
  const { isReferrer } = useAuth();
  const defaultProfileType: ProfileType = isReferrer ? REFERRER : APPLICANT;
  const [selectedStatus, setSelectedStatus] =
    useState<ProfileType>(defaultProfileType);

  const handleButtonClick = (_: SyntheticEvent, status: ProfileType) => {
    setSelectedStatus(status);
  };

  return (
    <Container>
      <Box sx={{ width: '100%' }}>
        <Tabs value={selectedStatus} onChange={handleButtonClick} centered>
          <Tab
            value={APPLICANT}
            label="Profile - 申請者"
            sx={{ fontSize: '1.2rem' }}
          />
          {isReferrer ? (
            <Tab
              value={REFERRER}
              label="Profile - 推薦者"
              sx={{ fontSize: '1.2rem' }}
            />
          ) : null}
        </Tabs>
      </Box>

      <Box sx={{ display: selectedStatus === APPLICANT ? 'block' : 'none' }}>
        <EditApplicantProfilePage />
      </Box>
      {isReferrer ? (
        <Box sx={{ display: selectedStatus === REFERRER ? 'block' : 'none' }}>
          <EditReferrerProfilePage />
        </Box>
      ) : null}
    </Container>
  );
};
