import React from 'react';

import { Box, Container } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Button } from 'components';
import { WHITE } from 'constants/colors';

import { ActionBarProps } from '../ApplyPage.types';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: WHITE,
      boxShadow: '0px -1px 0px rgba(224, 224, 224, 0.24)',
    },
    actionButtons: {
      gap: theme.spacing(2),
    },
  })
);

export const ActionBar: React.VFC<ActionBarProps> = ({
  isSubmitting,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      position="fixed"
      bottom={0}
      className={classes.root}
      zIndex={10}
      paddingY={2}
    >
      <Container>
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.actionButtons}
        >
          <Button color="secondary" onClick={onCancel} disabled={isSubmitting}>
            取消
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            送出
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
